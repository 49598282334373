/*
* 업무구분: 신탁>영업>변경/해지/이체>이체>입금>온라인입금처리_P
* 화 면 명: MSPTS200P(Nex : TFIO40001.xfdl)
* 화면설명: 온라인입금처리
* 작 성 일: 2023.03.28
* 작 성 자: 유동훈
* F10600159	selectTFIO40001	      [NEXT 사랑On 신탁]온라인입금 목록조회
* F10600154	selectTFIO40000AList	[NEXT 사랑On 신탁]온라인입금처리 목록조회
* F10600150	deleteTFIO40000A	    [NEXT 사랑On 신탁]신탁 기타입금 삭제
* F10600152	insertTFIO40000A	    [NEXT 사랑On 신탁]신탁 기타입금 처리
* F10600171	insertTFIO43000	      [NEXT 사랑On 신탁]온라인입금처리 등록
*/
<template>
<div>
    <!-- popup -->
    <mo-modal class="fts-modal medium" ref="modal1" title="온라인입금처리">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-table">
            <table class="table col-type col4060">
              <tbody>
                <tr>
                  <th>
                      입금일자
                  </th>
                  <td>
                    <div class="wrap-input">
                      <mo-date-picker class="input-long" :bottom="false" ref="cal_bizDate" v-model="ds_search.bizDate" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    지점코드
                  </th>
                  <td>
                    <div class="wrap-input">
                      <mo-text-field class="input-long" ref="edt_brcd"  v-model="ds_search.brcd" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    고객명
                  </th>
                  <td>
                    <div class="wrap-input">
                      <mo-text-field class="input-long" ref="edt_csNm"  v-model="ds_search.csNm" disabled/>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="wrap-table-title">
            <h2 class="table-title"> 가상계좌입금내역 </h2>
          </div>
          <div class="wrap-table">
            <table class="table col-type col2060 mb0">
              <tbody>
                <tr>
                  <th colspan="2">
                      처리가능금액
                  </th>
                  <td>
                    <div class="wrap-input row">
                       <mo-text-field mask="number" class="input-long" ref="edt_iAmt"    maxlength='15' v-model="ds_search.rcvAmt" disabled/> <span> 원 </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th rowspan="2">
                    실제처리금액
                  </th>
                  <th>
                    원본
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-decimal-field numeric mask="number" class="input-long" ref="edt_orginAmt"  maxlength='15' v-model="ds_search.orginAmt" :disabled="!(lcfeeWonbonGb == `1` && ds_detail.rcvAmt > 0)" :class="ds_error.edt_orginAmt ? 'error' : ''"/> <span> 원 </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    수수료
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-decimal-field numeric mask="number" class="input-long" ref="edt_fee"      maxlength='15' v-model="ds_search.fee"       :disabled="!(lcfeeWonbonGb == `2` && ds_detail.rcvAmt > 0)" :class="ds_error.edt_fee ? 'error' : ''" /> <span> 원 </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wrap-button result mt6" v-if="this.lcfeeWonbonGb == '2' && this.poaoTc == '1'">
            <mo-button @click="fn_InsertIamtAr()"> 별납요청 </mo-button>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point   size="large" @click="fn_Close()  ">닫기</mo-button>
          <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
      @childReturnEvt="returnEvt"
    ></ts-alert-popup>

</div>
</template>
<script>
  /***********************************************************************************
  * 공통 라이브러리 INCLUDE 영역	                                                   *
  ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil    from '~/src/ui/ts/comm/TSCommUtil'
  import TSAlertPopup  from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name       : "MSPTS200P",
    screenId   : "MSPTS200P",
    components : {
      'ts-alert-popup': TSAlertPopup,
    },
    props: {
      popupObj: {type:Object, default: null},
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {

    },
    mounted() {
      //this.fn_afterFormOnload()
      this.$bizUtil.insSrnLog("MSPTS200P")
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // Contents Model 
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

        ds_search01         : {},
        ds_search           : {},
        ds_detail           : {},
        ds_5095             : [],
        ds_5317             : [],

        ds_error            : {
          edt_orginAmt : false,
          edt_fee      : false,
        },

        advRegYn            : '', // 사전등록 여부 
        ectRegYn            : '', // 기타입금 여부 
        arYn                : '', // AR생성여부
        lcTacno             : '', // 종합계좌번호
        lcBnkbSeq           : '', // 통장일련번호
        lcAccSeq1           : '', // 계좌일련번호 
        lcAccSeq2           : '', // 계좌일련번호 화면에서 받은 계좌일련번호 
        lcfeeWonbonGb       : '', // 수수료 구분 
        
        
        reqAmt              : '', // 요청금액 
        poaoTc              : '', // 선후취구분코드
        contDate            : '', // 가입일자

        ds_userInfo         : this.getStore('tsStore').getters.getBasInfo.data,

        pAlertPopupObj      : {}, // 공통 객체
        objRtnArr           : {}, // 팝업창 close시 전달할 값을 위한 배열 선언
      }
    },

    /***********************************************************************************
     * filters 함수 정의 영역                                                         *
     ***********************************************************************************/
    filters: {
      comma(val) {
        if ( TSCommUtil.gfn_isNull(val) ) return ""
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // modal
      modal1() {return this.$refs.modal1},
    },

    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {},

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /***************************************************************************************
          화면로드
      *****************************************************************************************/
      fn_Load() {
        this.fn_comCode()
      },

      /***************************************************************************************
          화면 초기화 
      *****************************************************************************************/
      fn_Init() {
        this.ds_search.bizDate = TSCommUtil.gfn_dateReplace(1, this.ds_userInfo.busyDate) // 현재일자 처리  

        this.advRegYn      = "N"  // 사전등록 여부 
        this.ectRegYn      = "N"  // 기타입금 여부 
        this.arYn          = "N"  // AR 생성 여부
        
        this.lcTacno       = ""	  // 종합계좌번호
        this.lcBnkbSeq     = ""	  // 통장일련번호
        this.lcAccSeq1     = ""	  // 계좌일련번호
        this.lcAccSeq2     = ""	  // 계좌일련번호
        this.lcfeeWonbonGb = ""   // 수수료 구분 

        // 화면에서 값이 넘어온 경우에만 
        if ( !TSCommUtil.gfn_isNull(this.popupObj.lcTacno) ) {
        	this.lcTacno       = this.popupObj.lcTacno		    // 종합계좌번호
        	this.lcBnkbSeq     = this.popupObj.lcBnkbSeq		  // 통장일련번호
        	this.lcAccSeq1     = "000"	                      // 계좌일련번호
        	this.lcAccSeq2     = this.popupObj.lcAccSeq	      // 화면에서 받은 계좌일련번호
        	this.lcfeeWonbonGb = this.popupObj.lcfeeWonbonGb  // 수수료 구분
        	this.reqAmt        = this.popupObj.reqAmt	
        	this.poaoTc        = this.popupObj.poaoTc 
        	this.contDate      = this.popupObj.contDate 

        	this.ds_search.csNm = this.popupObj.csNm     // 고객명
        	this.ds_search.brcd = this.ds_userInfo.brcd  // 부서코드

        	this.ds_search.rcvAmt   = "0"  // 처리가능금액
        	this.ds_search.orginAmt = "0"  // 실제처리금액(원본)
        	this.ds_search.fee      = "0"  // 실제처리금액(수수료)

          this.fn_searchRctm() // 가상계좌입금내역확인 
        }
      },
      
      /***************************************************************************************
        code        : 호출할 공통코드
        dsName      : 호출된 공통코드를 담을 Dataset
        selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
        
        selecttype은 추후 변경될 수 있음.
        this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
      *****************************************************************************************/
      fn_comCode() {
          this.g_eaiId = "C392_F10600046_S"
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.auth = 'S'
          this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
          this.eaiCommObj.params = {'TUKEMK':[
                                      {cId:"5095"},  // cmb_rcnoKindTc 실명종류
                                      {cId:"5317"},  // cmb_incMnTc    소득자구분
                                  ]}
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack, this.fn_error)
      },
      
      /**********************************************************************************
       * CommonCode의  callback 함수 : 공통코드 조회후 콜백
       **********************************************************************************/
      fn_comCodeCallBack (res) {
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        for ( let index in res.data.tukemk ) {
          let item = res.data.tukemk[index]
          switch (item.cid) {
            case "5095":
              this.ds_5095.push({value: item.c , text: item.cnm})
              break
            case "5317":
              this.ds_5317.push({value: item.c , text: item.cnm})
              break
          }
        }
      },
      
      /***************************************************************************************
        개인가상계좌입금내역 조회
      *****************************************************************************************/
      fn_searchRctm() {
        //조회용 dataset에 조회할 항목을 넣어준다.
        this.ds_search.tacno   = this.lcTacno
        this.ds_search.bnkbSeq = this.lcBnkbSeq
        this.ds_search.accSeq  = this.lcAccSeq2	

        this.g_eaiId = "C392_F10600159_S" //selectTFIO40001
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params = this.ds_search

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600159, this.fn_error)
      },
      
      /**********************************************************************************
       * gfn_callService의 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      fn_callBack_F10600159(res) {

        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          if ( TSCommUtil.gfn_trim(resData.errorMsg) == '' ) {
            this.fn_AlertPopup(0,{content : resData.errorCode}) 
          } else {
            this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          }
          return false
        }

        this.ds_detail = resData
        this.ds_search.rcvAmt = this.ds_detail.rcvAmt

        // 처리항목활성화 
        if ( this.ds_detail.rcvAmt > 0 ) {
        	if ( this.lcfeeWonbonGb == "1" ) {
        		this.ds_search.orginAmt = this.reqAmt
            TSCommUtil.gfn_focus(this.$refs["edt_orginAmt"])
        	} else if ( this.lcfeeWonbonGb == "2" ) {
        		this.ds_search.fee      = this.reqAmt
            TSCommUtil.gfn_focus(this.$refs["edt_fee"])
        	}	
        }		
      },
      
      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              this.pAlertPopupObj.content = pPopupObj.content

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirm) ) {
                this.pAlertPopupObj.confirm = pPopupObj.confirm
              } else {
                this.pAlertPopupObj.confirm = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirmFunc) ) {
                this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              } else {
                this.pAlertPopupObj.confirmFunc = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.closeFunc) ) {
                this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
              } else {
                this.pAlertPopupObj.closeFunc = ''
              }
            }
            break
        }

        this.$refs.alertPopup.fn_Open()
      },

      // 팝업 확인, 닫기 event
      returnEvt (value) {
        console.log ( 'emit 이벤트 : ' +  value)
      },

      /******************************************************************************
       * Function명 : fn_Open
       * 설명       : 화면열기
       ******************************************************************************/
      fn_Open() {
        this.ds_error.edt_orginAmt = false
        this.ds_error.edt_fee      = false
        
        this.fn_Init()
        this.modal1.open()
      },

      /***************************************************************************************
        확인 
      *****************************************************************************************/
      fn_Confirm() {
        this.ds_error.edt_orginAmt = false
        this.ds_error.edt_fee      = false
        if ( TSCommUtil.gfn_isNull(this.ds_search.orginAmt) ) {
          this.ds_search.orginAmt = 0
        }
        if ( TSCommUtil.gfn_isNull(this.ds_search.fee) ) {
          this.ds_search.fee = 0
        }

        if ( Number(this.ds_search.orginAmt) + Number(this.ds_search.fee) > Number(this.ds_search.rcvAmt) ) {
          TSCommUtil.gfn_validate(this, '실제입금액이 처리가능금액보다 큽니다.')
        	if ( this.lcfeeWonbonGb == "1" ) {
            this.ds_error.edt_orginAmt = true
            TSCommUtil.gfn_focus(this.$refs["edt_orginAmt"])
        	} else if ( this.lcfeeWonbonGb == "2" ) {
            this.ds_error.edt_fee = true
            TSCommUtil.gfn_focus(this.$refs["edt_fee"])
        	}	
          return
        }
        
        this.objRtnArr = {}
        this.objRtnArr.lcfeeWonbonGb = this.lcfeeWonbonGb
        this.objRtnArr.rcvAmt        = this.ds_search.rcvAmt
        this.objRtnArr.orginAmt      = this.ds_search.orginAmt
        this.objRtnArr.fee           = this.ds_search.fee

        this.$emit('ts-popup200-callback', this.objRtnArr)
      },
      
      /******************************************************************************
       * Function명 : fn_Error
       * 설명       : 입력오류
       ******************************************************************************/
      fn_Error(ds_error) {
        this.ds_error.edt_orginAmt = ds_error.edt_orginAmt
        this.ds_error.edt_fee      = ds_error.edt_fee
      },

      /******************************************************************************
       * Function명 : fn_Close
       * 설명       : 닫기
       ******************************************************************************/
      fn_Close() {
        this.modal1.close()
      },

      /******************************************************************************
       * Function명 : fn_InsertIamtAr
       * 설명       : 별납요청
       ******************************************************************************/
      fn_InsertIamtAr() {
        // TO-BE ERP 일때만 적용되는 로직 
        this.ds_search01 = {}

        this.ds_search01.tacno      = this.lcTacno
        this.ds_search01.bnkbSeq    = this.lcBnkbSeq
        this.ds_search01.accSeq     = this.lcAccSeq2
        this.ds_search01.aprvBusnTc = ""
        this.ds_search01.pymet      = ""
          
        this.ds_search01.procCls    = "FEE"
        this.ds_search01.trAmt      = this.reqAmt
          
        this.g_eaiId = "C392_F10600171_S" //selectTFIO40001
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params = this.ds_search01

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600171, this.fn_error)
      },

      /**********************************************************************************
       * gfn_callService의 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      fn_callBack_F10600171(res) {

        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          if ( TSCommUtil.gfn_trim(resData.errorMsg) == '' ) {
            this.fn_AlertPopup(0,{content : resData.errorCode}) 
          } else {
            this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          }
          return false
        }

        this.objRtnArr = {}
        this.objRtnArr.popProsCls    = this.advRegYn                 // 처리구분 	
        this.objRtnArr.lcfeeWonbonGb = this.lcfeeWonbonGb
        this.objRtnArr.orginAmt      = this.ds_search.orginAmt       // 이체금액
        this.objRtnArr.fee           = this.ds_search.fee            //수수료금액 
        this.objRtnArr.arYn          = "Y";

        this.$emit('ts-popup200-callback', this.objRtnArr)
//        this.fn_Close()
      },
      
    }
  }
</script>
<style scoped>
</style>