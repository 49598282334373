/* * 업무구분: 신탁>영업>변경/해지/이체>변경/해지>신탁입금 * 화 면 명: MSPTS207M(Nex : TFIO40000.xfdl) * 화면설명: 신탁입금 * 작 성 일: 2023.03.24 * 작 성 자: 유동훈 * trnstId 변경 : (AsIs) svcId ==>
(ToBe) trnstId * trnstId 변경 : (AsIs) svcId ==> (ToBe) trnstId * F10600158 selectTFIO40000F [NEXT 사랑On 신탁]조력자 등록여부 조회 * F10600153 selectTFIO40000 [NEXT 사랑On 신탁]입금 상세조회 *
F10600151 insertTFIO40000 [NEXT 사랑On 신탁]신탁입금처리 * F10600156 selectTFIO40000C [NEXT 사랑On 신탁]입금 상세조회 * F10600172 selectTFIO43000B [NEXT 사랑On 신탁]입금 목록조회 * F10600155
selectTFIO40000B [NEXT 사랑On 신탁]수수료 관련 상세조회 * F10600157 selectTFIO40000E [NEXT 사랑On 신탁]파생결합증권 녹취 여부 조회 
 * 수정일 : 2024-07-16 정수진  사유 : ASR240700667 / [사랑on신탁] 서식_일괄체크리스트변경
 * 수정일 : 2024-08-06 정수진  사유 : ASR240701107 / 상품설명서 갱신에 따른 태블릿 전자서식 수정 
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <ts-header ref="tsHeader" :propObj="pHeaderObj"></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w100p mr0 gap40">
                <div class="wrap-input row">
                  <label class="emphasis w50"> 계좌번호 </label>
                  <mo-decimal-field
                    class="w130 input-account"
                    numeric
                    ref="edt_acno"
                    v-model="ds_searchAc.inAcno"
                    maxlength="11"
                    :disabled="lv_procInfo != null"
                    @keyup="fn_comEvent()"
                    :class="ds_error.edt_acno ? 'error' : ''"
                    mask="#######-###"
                  />
                  <mo-dropdown class="w250" :items="ds_acnoList" ref="cbo_accSeq" v-model="ds_searchAc.accSeq" @input="fn_changeAcno($event, 'cbo_accSeq')" :disabled="lv_procInfo != null" />
                  <mo-button class="btn-pop-download" @click="fn_OpenPopup('206p')" :disabled="lv_procInfo != null"> </mo-button>
                  <mo-text-field class="w130" ref="edt_cnm" v-model="ds_acno.cnm" disabled />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p">
                <div class="wrap-button row">
                  <label class="emphasis w50"> 구분 </label>
                  <mo-dropdown :items="ds_depositCls" class="w200" v-model="ds_search.depositCls" ref="cbo_depositCls" @input="fn_FeeComponentState()" />
                  <!-- 수정2023-04-27 : w150 -->
                  <mo-dropdown :items="ds_feeDetail" class="w200" v-model="ds_search.feeDetail" ref="cbo_feeDetail" v-if="ds_search.depositCls == 'FEE'" @input="fn_selectFee()" />
                  <!-- 수정2023-04-27 : v-if -->
                </div>
              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button @click="fn_init()" :disabled="lv_procInfo != null"> 초기화 </mo-button>
                  <mo-button @click="fn_searchTr()" primary> 조회 </mo-button>
                  <!-- <mo-button @click="fn_searchReport_temp()" primary> 전자문서 조회 </mo-button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start" v-if="ds_div.div01 == '1'">
        <div class="wrap-table-title row">
          <h2 class="table-title" v-if="ds_div.trstTypC != 'ETC'">입금금액</h2>
          <h2 class="table-title" v-if="ds_div.trstTypC == 'ETC'">입고금액</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2080">
            <tbody>
              <tr>
                <th>
                  <span> 승인신청금액 </span>
                </th>
                <td class="aR">
                  <div class="w265" ref="sta_admissAmt">{{ ds_detail01.admissAmt_S | comma }}</div>
                </td>
              </tr>
              <tr>
                <th>
                  <span ref="" v-if="ds_div.trstTypC != 'ETC'"> 현금입금액 </span>
                  <span ref="" v-if="ds_div.trstTypC == 'ETC'"> 현물입금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      class="w270"
                      numeric
                      mask="number"
                      maxlength="15"
                      ref="edt_cashIamt"
                      v-model="ds_detail01.cashIamt"
                      :disabled="ds_div.trstTypC != 'ETC'"
                      :class="ds_error.edt_cashIamt ? 'error' : ''"
                      @keyup="edt_cashIamt_onkeyup()"
                    />
                    <mo-button @click="fn_confirm()" primary v-if="ds_div.trstTypC == 'ETC'"> 확인 </mo-button>
                  </div>
                </td>
              </tr>
              <tr v-if="ds_div.trstTypC != 'ETC'">
                <th>
                  <span> 수표입금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_chekIamt" v-model="ds_detail01.chekIamt" />
                    <mo-text-field class="w130" mask="number" disabled ref="edt_chekIqty" v-model="ds_detail01.chekIqty" /> 매
                  </div>
                </td>
              </tr>
              <tr v-if="this.ds_div.trstTypC != 'ETC'">
                <th>
                  <span> 온라인입금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_onlineIamt" v-model="ds_detail01.onlineIamt" />
                    <mo-button primary @click="fn_OpenPopup('205pA')"> 온라인 </mo-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start" v-if="ds_div.div02 == '1'">
        <div class="wrap-table-title row">
          <h2 class="table-title" v-if="ds_div.trstTypC != 'ETC'">입금내역</h2>
          <h2 class="table-title" v-if="ds_div.trstTypC == 'ETC'">입고내역</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2080">
            <tbody>
              <tr>
                <th>
                  <span v-if="ds_div.trstTypC != 'ETC'"> 입금총액 </span>
                  <span v-if="ds_div.trstTypC == 'ETC'"> 입고총액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_iamtTot" v-model="ds_detail02.iamtTot" />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th>
                  <span> 신탁전잔 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_befBlct" v-model="ds_detail02.befBlct" />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th>
                  <span> 신탁금잔 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_aftBlct" v-model="ds_detail02.aftBlct" />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start" v-if="ds_div.div03 == '1'">
        <div class="wrap-table-title row">
          <h2 class="table-title">계약정보</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2080">
            <tbody>
              <tr>
                <th>
                  <span> 신탁금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_contAmt" v-model="ds_detail03.contAmt" />
                  </div>
                </td>
                <th>
                  <span> 보수구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="ds_5017" class="w200" ref="cbo_poaoTc" v-model="ds_detail03.poaoTc" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 만기일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="cal_mtrtDate" v-model="ds_detail03.mtrtDate" />
                  </div>
                </td>
                <th>
                  <span> (후취)기본보수율 </span>
                </th>
                <td>
                  <div class="wrap-input row"><mo-text-field class="w270" mask="number" disabled ref="edt_iamtTot00" v-model="ds_detail03.iamtTot00" />(%)</div>
                </td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <th>
                  <span> 선취수수료율 </span>
                </th>
                <td>
                  <div class="wrap-input row"><mo-text-field class="w270" mask="number" disabled ref="edt_iamtTot01" v-model="ds_detail03.iamtTot01" />(%)</div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 상품유형 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="ds_5014" class="w200" ref="cbo_trstTypC" v-model="ds_detail03.trstTypC" />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start" v-if="ds_div.div04 == '1'">
        <div class="wrap-table-title row">
          <h2 class="table-title">수수료금액</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span> 납부예정금액 </span>
                </th>
                <td class="aR">
                  <div class="w265" ref="feeAdmissAmt_S">{{ ds_detail04.feeAdmissAmt_S | comma }}</div>
                </td>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>
                  <span> 현금입금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_cashFeeAmt" v-model="ds_detail04.cashFeeAmt" />
                  </div>
                </td>
                <th>
                  <span> 수수료수납일 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-date-picker :bottom="false" class="input-long" ref="cal_feeDate" v-model="cal_feeDate" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 수표입금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_chekFeeAmt" v-model="ds_detail04.chekFeeAmt" />
                    <mo-text-field class="w130" mask="number" disabled ref="edt_chekFeeIqty" v-model="ds_detail04.chekFeeIqty" /> 매
                  </div>
                </td>
                <th>
                  <span v-if="ds_acno.trstTypC == '003'"> 기본보수 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_feeAdmissAmt" v-model="ds_detail04.feeAdmissAmt" v-if="ds_acno.trstTypC == '003'" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 온라인입금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_onlineFeeAmt" v-model="ds_detail04.onlineFeeAmt" />
                    <mo-button primary @click="fn_OpenPopup('205pB')"> 온라인 </mo-button>
                  </div>
                </td>
                <th>
                  <span v-if="ds_acno.trstTypC == '003'"> 부가세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_addFee" v-model="ds_detail04.addFee" v-if="ds_acno.trstTypC == '003'" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 입금총액 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" disabled ref="edt_feeIamtTot" v-model="ds_detail04.feeIamtTot" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>
      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <div class="left mt40">
            <mo-button size="large" @click="fn_movePage_MSPTS213M()" v-if="lv_procInfo != null && isLast != true && ds_detail01.admissAmt == '0'"> 다음 </mo-button>
            <!-- <mo-button size="large" @click="fn_searchReport_temp()"> 전자서식 호출 </mo-button> -->
          </div>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <ts-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj" @childReturnEvt="returnEvt"></ts-alert-popup>

    <!-- alert modal popup -->
    <ts-alert-popup ref="alertPopup2" :popupObj="pAlertPopupObj2" @childReturnEvt="returnEvt2"></ts-alert-popup>

    <!-- popup200 온라인입금처리팝업_P -->
    <msp-ts-200p ref="popup200" @ts-popup200-callback="fn_Popup_CallBack_200P" :popupObj="pPopup200Obj"></msp-ts-200p>

    <!-- popup206 계좌번호조회팝업_P -->
    <msp-ts-206p ref="popup206" @ts-popup206-callback="fn_Popup_CallBack_206P" :popupObj="pPopup206Obj"></msp-ts-206p>

    <!-- popup106 AR승인팝업_P -->
    <msp-ts-106p ref="popup106" @ts-popup106-callback="fn_Popup_CallBack_106P" :popupObj="pPopup106Obj"></msp-ts-106p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSComDate from '~/src/ui/ts/comm/TSComDate'
import TSHeader from '~/src/ui/ts/comm/TSHeader' // header 영역 (공통)
import TSInfoUtil from '~/src/ui/ts/comm/TSInfoUtil' //

// 팝업
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup' // Alert 팝업 (공통)
import MSPTS200P from '~/src/ui/ts/MSPTS200P' // 온라인입금처리팝업_P
import MSPTS206P from '~/src/ui/ts/MSPTS206P' // 계좌번호조회팝업_P
import MSPTS106P from '~/src/ui/ts/MSPTS106P' // AR승인팝업_P

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPTS207M',
  screenId: 'MSPTS207M',
  components: {
    'ts-header': TSHeader,
    'ts-alert-popup': TSAlertPopup,
    'msp-ts-200p': MSPTS200P,
    'msp-ts-206p': MSPTS206P,
    'msp-ts-106p': MSPTS106P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.fn_Load()
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // Contents Model
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

      // 공통 객체
      pHeaderObj: {
        title: '신탁입금',
        type: 'trsf',
        step: 2
      },

      ds_5014: [],
      ds_5017: [],
      ds_5165: [],
      ds_5186: [],
      ds_depositCls: [],
      ds_feeDetail: [],

      ds_searchAc: {
        inAcno: '',
        tacno: '',
        bnkbSeq: '',
        accSeq: ''
      }, // 계좌정보 조회
      ds_acno: {}, // 계좌정보
      ds_acnoList: [], // 계좌정보List

      ds_search: {}, // 검색조건
      ds_detail01: {}, // 입금금액
      ds_detail02: {}, // 입금내역
      ds_detail03: {}, // 계약정보
      ds_detail04: {}, // 수수료금액

      ds_detailAmt: {}, // 입고금액
      ds_detailFee: {}, // 수수료금액

      // 화면에 보여주기
      ds_div: {
        div01: '',
        div02: '',
        div03: '',
        div04: '',
        trstTypC: '' // 특전금전신탁이 아닌 경우 화면 컨트롤
      },

      ds_error: {
        edt_acno: false,
        edt_cashIamt: false,
        edt_orginAmt: false,
        edt_fee: false
      },

      ds_tfaacb: {}, // 계좌_계좌기본
      ds_tftcob: {}, // 계약_계약기본
      ds_tfcprb: {}, // 고객_상품기본
      ds_tfgoil: {}, // 전문_OpenItem(AR 조회)
      ds_tfio40000: {}, // 신탁입금
      ds_passbook: {}, // 통장인쇄

      vDepositCls: '', // 입금처리구분
      oldAge: '', //2021-02-09 add

      pAlertPopupObj: {}, // 공통 객체
      pAlertPopupObj2: {}, // 공통 객체
      pPopup200Obj: {}, // 온라인입금처리팝업_P
      pPopup206Obj: {}, // 계좌번호조회팝업_P
      pPopup106Obj: {}, // AR승인팝업_P
      pPopup510Obj: {}, // 본인인증팝업_P

      ds_searchReportList: [], // 신탁계약서출력조회
      ds_resultReportList: [], // 신탁계약서출력조회
      ds_searchReport: {}, // 신탁계약서출력조회

      ds_report: {}, // 신탁계약서출력조회
      ds_report_A: {}, // 신탁계약서출력조회_일괄체크리스트 및 확인서
      ds_report_D: {}, // 신탁계약서출력조회_적합성 보고서
      ds_report_C: {}, // 신탁계약서출력조회_수수료입금

      ds_userInfo: this.getStore('tsStore').getters.getBasInfo.data,
      lv_process: this.getStore('tsStore').getters.getState.isProcess,
      isLast: this.$bizUtil.tsUtils.getIsLastProc(this),
      // 로그인정보
      lv_procInfo: null,
      lv_sendInfo: null,

      lv_report: ''
    }
  },

  /***********************************************************************************
   * filters 함수 정의 영역                                                         *
   ***********************************************************************************/
  filters: {
    comma(val) {
      if (TSCommUtil.gfn_isNull(val)) return ''
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },

  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    cal_feeDate: {
      set: function(param) {
        let repParam = param.replaceAll('-', '')
        this.ds_detail04.feeDate = repParam
      },
      get: function() {
        return TSCommUtil.gfn_dateReplace(1, this.ds_detail04.feeDate)
      }
    },
    isStep() {
      return this.getStore('tsStore').getters.getState.isProcess
    }
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_dsErrorClear() {
      this.ds_error = {
        edt_acno: false,
        edt_cashIamt: false,
        edt_orginAmt: false,
        edt_fee: false
      }
    },

    fn_dsAcnoClear() {
      this.ds_acnoList = []
      this.ds_acno = {
        acno: '', // 계좌번호
        acc: '', // 계좌일련번호
        accSeq: '', // 계좌일련번호
        accStatC: '', // 계좌상태코드
        acctNo: '', // 계좌번호
        admkNm: '', // 부기명
        bnkb: '', // 통장일련번호
        bnkbAccYn: '', // 계좌여부
        bnkbSeq: '', // 통장일련번호
        brigAccYn: '', // 브릿지계좌여부
        cnm: '', // 성명
        contDate: '', // 계약일자
        csId: '', // 고객ID
        curC: '', // 통화코드
        fundC: '', // 펀드코드
        gdC: '', // 상품코드
        gdNm: '', // 상품명
        gdTypDtlC: '', // 상품유형상세코드
        mngBrcd: '', // 관리지점코드
        mtrtDate: '', // 만기일
        pwd: '', // 암호화 주민번호(PWD)
        pwdCls: '', // 계좌비밀번호 체크 구분
        rcno: '', // 주민등록번호
        rcnoKindTc: '', // 실명번호종류구분코드
        soptTc: '', // 자타익구분코드
        tacno: '', // 종합계좌번호
        trstAmt: '', // 신탁금액
        trstTypC: '', // 신탁유형코드
        value: '', //
        text: '' //
      }
    },

    /***********************************************************************************
     * this.ds_search 초기화                                                      *
     ***********************************************************************************/
    fn_dsSearchClear() {
      this.ds_searchAc = {
        inAcno: '',
        tacno: '',
        bnkbSeq: '',
        accSeq: ''
      }
      this.ds_search = {
        passbookUseYn: '', // 통발기 사용여부

        ctno: '', // 계약번호
        aprvNo: '0', // 승인신청번호
        bizDate: '', // 영업일자
        accStatC: '', // 계좌상태
        depositCls: '', // 신규/추가 구분
        feeDetail: '', // 수수료 구분
        accStatC: '', // 계좌상태코드지정

        msUseYn: '', // 통장리드 여부
        msData: '', // 통장ms 데이터 항목
        epayPoaoTc: '', // 별납선후취구분
        prfe: '0', // 선취수수료금액
        teleVssTc: '', // 유선내방여부
        mngAprvSeq: '0' // 승인일련번호
      }

      this.ds_div.div01 = '1'
      this.ds_div.div02 = '1'
      this.ds_div.div03 = ''
      this.ds_div.div04 = ''
      this.ds_div.trstTypC = ''

      this.ds_search.bizDate = this.ds_userInfo.busyDate
      this.ds_search.msUseYn = '0'
    },

    fn_dsDetail01Clear() {
      this.ds_detail01 = {
        admissAmt_S: '0', // 승인신청금액_S
        admissAmt: '0', // 승인신청금액
        cashIamt: '0', // 현금입금액
        chekIamt: '0', // 수표입금액
        chekIqty: '0', // 수표입금액 매수
        onlineIamt: '0', // 온라인입금액

        linkAcctProcAmt: '0', // 가상계좌처리금액
        prfe: '0', // 선취수수료금액

        ftrnAmt: '0', // 이체금액
        ftrnIamtDate: '', // 이체입금일
        iamtAccDepoNm: '', // 입금계좌예금주
        iamtAcno: '', // 입금계좌번호
        iamtBkcd: '' // 입금은행코드
      }
    },

    fn_dsDetail02Clear() {
      this.ds_detail02 = {
        iamtTot: '0', // 입금총액
        befBlct: '0', // 신탁전잔
        aftBlct: '0' // 신탁금잔
      }
    },

    fn_dsDetail03Clear() {
      this.ds_detail03 = {
        contAmt: '', // 신탁금액
        poaoTc: '', // 보수구분
        mtrtDate: '', // 만기일
        iamtTot00: '', // (후취)기본보수율
        iamtTot01: '', // 선취수수료율
        trstTypC: '' // 상품유형
      }
    },

    fn_dsDetail04Clear() {
      this.ds_detail04 = {
        feeAdmissAmt_S: '0', // 승인신청금액_S
        feeAdmissAmt00: '0', // 승인신청금액
        cashFeeAmt: '0', // 현금입금액
        feeDate: '', // 수수료수납일
        chekFeeAmt: '0', // 수표입금액
        chekFeeIqty: '0', // 수표입금액 매수
        feeAdmissAmt: '0', // 기본보수
        onlineFeeAmt: '0', // 온라인입금액
        addFee: '0', // 부가세
        feeIamtTot: '0', // 입금총액

        linkAcctFeeAmt: '0', // 가상계좌수수료처리금액

        feeFtrnAmt: '0', // 수수료이체금액
        feeFtrnIamtDate: '', // 수수료이체입금일
        feeIamtAccDepoNm: '', // 수수료입금계좌예금주
        feeIamtAcno: '', // 수수료입금계좌번호
        feeIamtBkcd: '' // 수수료입금은행코드
      }
    },

    /***************************************************************************************
          초기화 
      *****************************************************************************************/
    fn_Load() {
      if (this.ds_userInfo == undefined) {
        this.fn_AlertPopup(0, { content: '로그인 정보가 없습니다. 로그인 화면으로 이동합니다.', confirm: true, confirmFunc: this.fn_moveLogin, single: true })
        return
      }

      if (this.$router.currentRoute.params !== undefined && this.$router.currentRoute.params.tacno !== undefined) {
        this.lv_procInfo = {}
        this.lv_procInfo.tacno = this.$router.currentRoute.params.tacno
        this.lv_procInfo.bnkbSeq = this.$router.currentRoute.params.bnkbSeq
        this.lv_procInfo.accSeq = this.$router.currentRoute.params.accSeq
      }
      this.ds_search.PassbookUseYn = 'Y' // 통발기 사용여부
      this.ds_search.accStatC = '11' // 계좌상태코드지정
      this.ds_search.bizDate = this.ds_userInfo.busyDate

      this.fn_comCode()
    },

    /***************************************************************************************
          로그인화면 이동
      *****************************************************************************************/
    fn_moveLogin() {
      this.$router.push({ name: 'MSPBC600M' })
    },

    /***************************************************************************************
          홈화면 이동
      *****************************************************************************************/
    fn_moveHome() {
      this.$router.push({ name: 'MSPTS000M' })
    },

    /***************************************************************************************
        공통코드 호출. ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      *****************************************************************************************/
    fn_comCode() {
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {
        TUKEMK: [
          { cId: '5014' }, // ds_5014    상품유형
          { cId: '5017' }, // ds_5017    보수구분
          { cId: '5165' }, // ds_5165    부가세율
          { cId: '5186' } // ds_5186    고령기준연령
        ]
      }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack, this.fn_error)
    },

    /***************************************************************************************
        공통코드 호출 CallBack
      *****************************************************************************************/
    fn_comCodeCallBack(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }

      for (let index in res.data.tukemk) {
        let item = res.data.tukemk[index]
        switch (item.cid) {
          case '5014':
            this.ds_5014.push({ value: item.c, text: item.cnm })
            break
          case '5017':
            this.ds_5017.push({ value: item.c, text: item.cnm })
            break
          case '5165':
            this.ds_5165.push({ value: item.c, text: item.cnm })
            break
          case '5186':
            this.ds_5186.push({ value: item.c, text: item.cnm })
            break
        }
      }

      //Combo에 최상단Row보이도록 설정(선택사항)
      this.ds_detail03.trstTypC = this.ds_5014[0].value
      this.ds_detail03.poaoTc = this.ds_5017[0].value

      //고령기준 연령 공통코드가져오기
      this.oldAge = this.ds_5186[0].text
      this.fn_init()

      TSCommUtil.gfn_focus(this.$refs['edt_acno'])
    },

    /***************************************************************************************
          초기화 
      *****************************************************************************************/
    fn_init() {
      this.ds_div.div01 = '1'
      this.ds_div.div02 = '1'

      this.fn_userInfoInit() // 사용자정보초기화
      this.fn_searchAmtInit() // 조회금액항목초기화
      this.fn_iamtInit() // 입금금액항목초기화
      this.fn_feeAmtInit() // 수수료금액항목초기화

      this.$nextTick(() => {
        if (!TSCommUtil.gfn_isNull(this.$route.params.tacno)) {
          this.ds_searchAc.inAcno = this.$route.params.tacno + this.$route.params.bnkbSeq
          this.ds_searchAc.accSeq = this.$route.params.accSeq
          this.fn_searchAcno()
        }
      })
    },

    /***************************************************************************************
          사용자정보초기화 
      *****************************************************************************************/
    fn_userInfoInit() {
      this.fn_dsAcnoClear()
      this.fn_dsSearchClear()

      // 초기 입금 구분 데이터 셋
      this.ds_depositCls = []
      this.ds_depositCls.push({ value: 'NEW', text: '신탁입금/입고' })
      this.ds_depositCls.push({ value: 'FEE', text: '수수료입금' })

      this.ds_search.depositCls = this.ds_depositCls[0].value
    },

    /***************************************************************************************
          조회 금액항목 초기화 
      *****************************************************************************************/
    fn_searchAmtInit() {
      this.fn_dsDetail02Clear()
      this.fn_dsDetail03Clear()
    },

    /***************************************************************************************
          입금 금액항목 초기화 
      *****************************************************************************************/
    fn_iamtInit() {
      this.fn_dsErrorClear()
      this.fn_dsDetail01Clear()
    },

    /***************************************************************************************
          수수료 금액항목 초기화 
      *****************************************************************************************/
    fn_feeAmtInit() {
      this.fn_dsDetail04Clear()
    },

    /***************************************************************************************
          조회용 TR 전송 
      *****************************************************************************************/
    fn_searchTr() {
      // 계좌번호 입력 체크
      if (TSCommUtil.gfn_isNull(this.ds_acno.acno) || this.ds_acno.acno.length != 13) {
        TSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다.')
        this.ds_error.edt_acno = true
        TSCommUtil.gfn_focus(this.$refs['edt_acno'])
        return false
      }

      //조회용 dataset에 조회할 항목을 넣어준다.
      this.fn_iamtInit() // 입금 금액항목 초기화
      this.fn_feeAmtInit() // 수수료 금액항목 초기화
      this.fn_searchAmtInit() // 조회 금액항목 초기화

      this.ds_search01 = {}
      this.ds_search01.tacno = this.ds_acno.tacno
      this.ds_search01.bnkbSeq = this.ds_acno.bnkbSeq
      this.ds_search01.accSeq = this.ds_acno.accSeq

      this.g_eaiId = 'C392_F10600153_S' //selectTFIO40000
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
      this.eaiCommObj.params = this.ds_search01

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600153, this.fn_error)
    },

    /***************************************************************************************
          계약 정보 조회 후 콜백 
      *****************************************************************************************/
    fn_callBack_F10600153(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }

      if (resData.tfaacb == null) {
        TSCommUtil.gfn_validate(this, '화면처리 시 오류가 발생하였습니다.')
        //          this.fn_AlertPopup(0,{content : "화면처리 시 오류가 발생하였습니다."})
        return false
      }

      this.ds_tfaacb = resData.tfaacb[0]
      this.ds_tftcob = resData.tftcob[0]
      this.ds_tfcprb = resData.tfcprb[0]

      this.ds_detail03.poaoTc = this.ds_tfaacb.poaoTc
      this.ds_detail03.mtrtDate = this.ds_tfaacb.mtrtDate
      this.ds_detail03.iamtTot00 = this.ds_tfaacb.basTfrt
      this.ds_detail03.iamtTot01 = this.ds_tfaacb.prfeRt
      this.ds_detail03.trstTypC = this.ds_tfaacb.trstTypC

      this.ds_search.ctno = this.ds_tfaacb.ctno
      this.ds_search.epayPoaoTc = this.ds_tfaacb.epayPoaoTc
      this.ds_detail02.befBlct = this.ds_tfaacb.trstAmt

      this.ds_search.aprvNo = '0'
      this.ds_search.msUseYn = '' // 통장출력관련
      this.ds_search.msData = '' // 통장출력관련

      // 특전금전신탁인 경우
      if (this.ds_acno.trstTypC == '001' || this.ds_acno.trstTypC == '007' || this.ds_acno.trstTypC == '008') {
        // 특전금전신탁인 경우 화면 컨트롤
        this.ds_div.trstTypC = ''

        // 브릿지 계좌여부
        if (this.ds_acno.brigAccYn == 'Y') {
          this.ds_depositCls = []
          this.ds_depositCls.push({ value: 'BRIDGE', text: '입금(브릿지계좌)' })
          this.ds_search.depositCls = this.ds_depositCls[0].value
          this.ds_search.aprvNo = '0'
        } else {
          this.ds_depositCls = []
          this.ds_depositCls.push({ value: 'NEW', text: '신탁입금/입고' })
          this.ds_depositCls.push({ value: 'FEE', text: '수수료입금' })
          this.ds_search.depositCls = this.ds_depositCls[0].value
        }
      } else {
        // 특전금전신탁이 아닌 경우 화면 컨트롤
        this.ds_div.trstTypC = 'ETC'

        this.ds_depositCls = []
        this.ds_depositCls.push({ value: 'NEW', text: '신탁입금/입고' })
        this.ds_depositCls.push({ value: 'FEE', text: '수수료입금' })
        this.ds_search.depositCls = this.ds_depositCls[0].value
      }

      this.fn_FeeComponentState()
    },

    /***************************************************************************************
      	입금 입고 금액 조회 
      *****************************************************************************************/
    fn_iamtSearchTr() {
      this.vDepositCls = '' // 신규/추가구분

      this.ds_search03 = {}
      this.ds_search03.tacno = this.ds_acno.tacno
      this.ds_search03.bnkbSeq = this.ds_acno.bnkbSeq
      this.ds_search03.accSeq = this.ds_acno.accSeq
      this.ds_search03.procCls = this.ds_search.depositCls

      if (this.ds_search.depositCls == 'FEE') {
        this.ds_search03.feeCls = this.ds_search.feeDetail
      }

      this.g_eaiId = 'C392_F10600156_S' //selectTFIO40000C
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
      this.eaiCommObj.params = this.ds_search03

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600156, this.fn_error)
    },

    /***************************************************************************************
          입금 입고 금액 조회 후 콜백 
      *****************************************************************************************/
    fn_callBack_F10600156(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }

      this.ds_tfio40000 = resData

      this.vDepositCls = this.ds_tfio40000.newAddCls // 입금처리구분
      this.ds_detail01.admissAmt_S = this.ds_tfio40000.trAmt // 승인금액
      this.ds_detail01.admissAmt = this.ds_tfio40000.trAmt // 승인금액
      this.ds_search.aprvNo = this.ds_tfio40000.aprvNo // 승인번호

      // 승인금액이 있는경우 AR조회
      if (this.ds_tfio40000.trAmt > 0) {
        this.fn_arSearchTr()
      }
    },

    /***************************************************************************************
      	AR 조회 
      *****************************************************************************************/
    fn_arSearchTr() {
      this.ds_search04 = {}

      this.ds_search04.tacno = this.ds_acno.tacno
      this.ds_search04.bnkbSeq = this.ds_acno.bnkbSeq
      this.ds_search04.accSeq = this.ds_acno.accSeq
      this.ds_search04.aprvNo = this.ds_tfio40000.aprvNo
      this.ds_search04.trDate = this.ds_search.bizDate

      this.g_eaiId = 'C392_F10600172_S' // selectTFIO43000B
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
      this.eaiCommObj.params = this.ds_search04

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600172, this.fn_error)
    },

    /***************************************************************************************
          AR 조회 후 콜백 
      *****************************************************************************************/
    fn_callBack_F10600172(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }

      this.ds_tfgoil = resData.tfgoil[0]

      // MSPTS106P
      // AR 팝업 호출
      if (TSCommUtil.gfn_isNull(this.ds_tfgoil.opbel)) {
        let alertMsg1 = ''
        alertMsg1 = '계약[' + this.ds_acno.tacno + '-' + this.ds_acno.bnkbSeq + '-' + this.ds_acno.accSeq + '] 건'

        let alertMsg2 = '<br>'
        if (this.ds_tfgoil.aprvBusnTc1 == '1011') {
          alertMsg2 = alertMsg2 + '신규계약'
        } else if (this.ds_tfgoil.aprvBusnTc1 == '1031') {
          alertMsg2 = alertMsg2 + '추가입금'
        } else if (this.ds_tfgoil.aprvBusnTc1 == '4011') {
          alertMsg2 = alertMsg2 + '계약해지'
        }
        alertMsg2 = alertMsg2 + ' 승인처리가 완료 되었습니다.'

        let alertMsg3 = '<br>'
        if (this.ds_tfgoil.aprvBusnTc1 == '1011') {
          alertMsg3 = alertMsg3 + '개인가상계좌로 입금하시겠습니까?'
        } else if (this.ds_tfgoil.aprvBusnTc1 == '1031') {
          alertMsg3 = alertMsg3 + '개인가상계좌로 입금하시겠습니까?'
        } else if (this.ds_tfgoil.aprvBusnTc1 == '4011') {
          alertMsg3 = alertMsg3 + '해지신청서 출력화면으로 이동하시겠습니까?'
        }

        let alertMsg4 = '<br>'
        if (this.ds_tfgoil.aprvBusnTc1 == '1011') {
          alertMsg4 = ''
        } else if (this.ds_tfgoil.aprvBusnTc1 == '1031') {
          alertMsg4 = ''
        } else if (this.ds_tfgoil.aprvBusnTc1 == '4011') {
          alertMsg4 = alertMsg4 + '출금은 본사 계리 처리 후 가능합니다.'
        }

        if (this.ds_tfgoil.aprvBusnTc1 == '4011') {
          this.fn_AlertPopup(0, { content: alertMsg1 + alertMsg2 + alertMsg3 + alertMsg4, confirm: true, confirmFunc: this.fn_confirmTrueAr })
        }

        this.fn_confirmTrueAr()
      }
    },

    /***************************************************************************************
          AR 처리
      *****************************************************************************************/
    fn_confirmTrueAr() {
      if (this.ds_tfgoil.aprvBusnTc1 == '1011' || this.ds_tfgoil.aprvBusnTc1 == '1031') {
        let ds_ar = {}

        ds_ar.aprvBusnTc = this.ds_tfgoil.aprvBusnTc1
        ds_ar.tacno = this.ds_acno.tacno
        ds_ar.bnkbSeq = this.ds_acno.bnkbSeq
        ds_ar.accSeq = this.ds_acno.accSeq

        this.g_eaiId = 'C392_F10600171_S' //insertTFIO43000
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params = ds_ar

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600171, this.fn_error)
      } else if (this.ds_tfgoil.aprvBusnTc1 == '4011') {
        let ds_move = {}
        ds_move.tacno = this.ds_acno.tacno
        ds_move.bnkbSeq = this.ds_acno.bnkbSeq
        ds_move.ctno = this.ds_acno.accSeq

        this.$router.push({ name: 'MSPTS201M', params: ds_move })
      }
    },

    /***************************************************************************************
          AR 조회 후 콜백 
      *****************************************************************************************/
    fn_callBack_F10600171(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }
    },

    /***************************************************************************************
          확인 처리시 입력값 체크 
      *****************************************************************************************/
    fn_confirmValueCheck() {
      // 계좌번호 입력 체크
      if (TSCommUtil.gfn_isNull(this.ds_acno.acno) || this.ds_acno.acno.length != 13) {
        TSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다.')
        this.ds_error.edt_acno = true
        TSCommUtil.gfn_focus(this.$refs['edt_acno'])
        return false
      }

      if (this.ds_search.depositCls == 'NEW') {
        if (Number(this.ds_detail02.iamtTot) <= 0) {
          TSCommUtil.gfn_validate(this, '입금액을 확인하십시오.')
          if (this.ds_div.trstTypC != 'ETC') {
            this.ds_error.edt_orginAmt = true
            this.$refs.popup200.fn_Error(this.ds_error)
          }
          if (this.ds_div.trstTypC == 'ETC') {
            this.ds_error.edt_cashIamt = true
            TSCommUtil.gfn_focus(this.$refs['edt_cashIamt'])
          }
          return false
        }

        if (Number(this.ds_detail02.iamtTot) != Number(this.ds_detail01.admissAmt)) {
          TSCommUtil.gfn_validate(this, '입금금액 합계가 승인신청금액과 같아야합니다.')
          if (this.ds_div.trstTypC != 'ETC') {
            this.ds_error.edt_orginAmt = true
            this.$refs.popup200.fn_Error(this.ds_error)
          }
          if (this.ds_div.trstTypC == 'ETC') {
            this.ds_error.edt_cashIamt = true
            TSCommUtil.gfn_focus(this.$refs['edt_cashIamt'])
          }
          return false
        }

        if (TSCommUtil.gfn_isNull(this.ds_search.aprvNo) || this.ds_search.aprvNo == '0') {
          TSCommUtil.gfn_validate(this, '승인신청내역이 없습니다.')
          //        		this.fn_AlertPopup(0,{content : "승인신청내역이 없습니다."})
          return false
        }

        this.ds_detail03.contAmt = this.ds_detail01.admissAmt // 신탁금액 = 승인금액
      } else if (this.ds_search.depositCls == 'FEE') {
        if (Number(this.ds_detail04.feeIamtTot) <= 0) {
          TSCommUtil.gfn_validate(this, '입금액을 확인하십시오.')
          this.ds_error.edt_fee = true
          this.$refs.popup200.fn_Error(this.ds_error)
          return false
        }

        if (this.ds_search.feeDetail != '2') {
          if (Number(this.ds_detail04.feeIamtTot) != Number(this.ds_detail04.feeAdmissAmt00)) {
            TSCommUtil.gfn_validate(this, '입금금액 합계가 납부예정금액과 같아야합니다.')
            this.ds_error.edt_fee = true
            this.$refs.popup200.fn_Error(this.ds_error)
            return false
          }
        }

        this.ds_detail03.contAmt = this.ds_detail04.feeAdmissAmt00 // 신탁금액 = 승인금액
      } else if (this.ds_search.depositCls == 'BRIDGE') {
        // 브릿지
        if (Number(this.ds_detail02.iamtTot) <= 0) {
          TSCommUtil.gfn_validate(this, '입금액을 확인하십시오.')
          this.ds_error.edt_orginAmt = true
          this.$refs.popup200.fn_Error(this.ds_error)
          return false
        }

        this.ds_detail03.contAmt = this.ds_detail02.iamtTot // 브릿지 입금액
      }

      /************수표 체크로직 추가 20060420****************/
      /* 테블릿에서는 수표 처리 불가
      	if ( Number(this.ds_detail01.chekIamt) <= 0 )	{
      		if ( Number(this.ds_detail01.chekIqty) > 0 ) {
        		this.fn_AlertPopup(0,{content : "수표매수 입력오류입니다."}) 
            this.$refs["edt_chekIqty"].focus()
      			return false
      		}
      	}	else {
      		if (Number(this.ds_detail01.chekIqty) <= 0)	{
        		this.fn_AlertPopup(0,{content : "수표매수 입력오류입니다."}) 
            this.$refs["edt_chekIqty"].focus()
      			return false
      		}
      	}
        */

      // 선취후취구분 && 별납 선추휘 구분 &&
      if (this.ds_detail03.poaoTc == '1' && this.ds_search.epayPoaoTc == '1' && this.ds_search.depositCls != 'FEE') {
        //수수료거래일경우는 예외처리
        if (this.ds_detail04.feeIamtTot != this.ds_search.prfe) {
          TSCommUtil.gfn_validate(this, '선취수수료을 확인하십시오.')
          //        		this.fn_AlertPopup(0,{content : "선취수수료을 확인하십시오."})
          return false
        }
        // 신탁금액 + 선취수수료금액
        var totAmt = Number(this.ds_detail03.contAmt) + Number(this.ds_search.prfe)
        if (this.ds_detail02.iamtTot != totAmt) {
          TSCommUtil.gfn_validate(this, '입금총금액을 확인하십시오.')
          //        		this.fn_AlertPopup(0,{content : "입금총금액을 확인하십시오."})
          return false
        }
      }
    },

    /***************************************************************************************
          확인 처리 
      *****************************************************************************************/
    fn_confirm() {
      this.fn_dsErrorClear()

      // if ( this.ds_userInfo.brcd == "916" ) {
      //   TSCommUtil.gfn_validate(this, '신탁지원부는 처리 할 수 없습니다.')
      // 	return false
      // }

      // 입력값체크
      if (this.fn_confirmValueCheck() == false) {
        return
      }

      // 승인자 사번 이 없는 경우에는 바로 처리 하도록 한다. 화면에서 입금할 예정금액을 표시후 처리
      if (this.ds_search.mngAprvSeq == '0') {
        if (this.ds_search.depositCls == 'FEE') {
          this.fn_AlertPopup(0, { content: '입금하실 금액이 ' + this.fn_comma(this.ds_detail04.feeIamtTot) + '원 입니다.', confirm: true, confirmFunc: this.fn_confirmTrue })
          return false
        } else {
          this.fn_AlertPopup(0, { content: '입금하실 금액이 ' + this.fn_comma(this.ds_detail02.iamtTot) + '원 입니다.', confirm: true, confirmFunc: this.fn_confirmTrue })
          return false
        }
      } else {
        this.fn_confirmTrue()
      }
    },

    /***************************************************************************************
          확인 처리 
      *****************************************************************************************/
    fn_confirmTrue() {
      // 거래내역 데이터 세팅
      this.ds_search01 = {}

      this.ds_search01.tacno = this.ds_acno.tacno // 종합계좌번호
      this.ds_search01.bnkbSeq = this.ds_acno.bnkbSeq // 통장일련번호
      this.ds_search01.accSeq = this.ds_acno.accSeq // 계좌일련번호
      this.ds_search01.csId = this.ds_acno.csId // 고객ID
      this.ds_search01.gdC = this.ds_acno.gdC // 상품코드

      this.ds_search01.mngBrcd = this.ds_acno.mngBrcd // 관리지점코드
      this.ds_search01.procBrcd = this.ds_userInfo.brcd // 처리지점코드

      this.ds_search01.cashAmt = this.ds_detail01.cashIamt // 현금금액
      this.ds_search01.chkAmt = this.ds_detail01.chekIamt // 수표금액
      this.ds_search01.chkNos = this.ds_detail01.chekIqty // 수표매수
      this.ds_search01.ftrnAmt = this.ds_detail01.onlineIamt // 이체금액

      this.ds_search01.prfeChkAmt = this.ds_detail04.chekFeeAmt // 선취수수료수표금액
      this.ds_search01.prfeChkQty = this.ds_detail04.chekFeeIqty // 선취수수료수표수량
      this.ds_search01.prfeFtrnAmt = this.ds_detail04.onlineFeeAmt // 선취수수료이체금액
      this.ds_search01.prfeCashAmt = this.ds_detail04.cashFeeAmt // 선취수수료현금금액
      this.ds_search01.stax = this.ds_detail04.addFee // 부가세

      this.ds_search01.aprvReqTc = this.ds_search.teleVssTc // 승인요청구분코드 -- 유선내방구분
      this.ds_search01.persnVaIamtAmt = this.ds_detail01.linkAcctProcAmt // 개인가상계좌입금금액
      this.ds_search01.persnVaPrfeIamtAmt = this.ds_detail04.linkAcctFeeAmt // 개인가상계좌선취수수료입금금액

      this.ds_search02 = {}

      this.ds_search02.msUseYn = this.ds_search.msUseYn // 통장출력관련
      this.ds_search02.msData = this.ds_search.msData // 통장출력관련
      this.ds_search02.aprvNo = this.ds_search.aprvNo // 승인신청번호
      this.ds_search02.depositCls = this.ds_search.depositCls // 신규, 추가 구분값

      if (Number(this.ds_detail01.onlineIamt) > 0) {
        this.ds_search02.onlineYn = 'Y' // 온라인입금여부
      } else {
        this.ds_search02.onlineYn = 'N' // 온라인입금여부
      }

      if (Number(this.ds_detail04.onlineFeeAmt) > 0) {
        this.ds_search02.feeOnlineYn = 'Y' // 온라인수수료입금여부
      } else {
        this.ds_search02.feeOnlineYn = 'N' // 온라인수수료입금여부
      }

      this.ds_search02.mngAprvSeq = this.ds_search.mngAprvSeq // 승인일련번호
      if (this.ds_search.depositCls == 'FEE') {
        this.ds_search02.totIamt = this.ds_detail04.feeIamtTot // 수수료총입금액
      } else {
        this.ds_search02.totIamt = this.ds_detail02.iamtTot // 총입금액
      }

      this.ds_search02.linkAcctProcAmt = this.ds_detail01.linkAcctProcAmt // 가상계좌 처리금액
      this.ds_search02.linkAcctFeeAmt = this.ds_detail04.linkAcctFeeAmt // 가상계좌 수수료 처리 금액
      this.ds_search02.brigAccYn = this.ds_acno.brigAccYn // 브릿지계좌여부
      this.ds_search02.trstAmt = this.ds_detail03.contAmt // 신탁금액
      this.ds_search02.ctno = this.ds_search.ctno // 계약번호

      if (this.ds_search.depositCls == 'FEE') {
        this.ds_search02.feeClsc = this.ds_search.feeDetail //수수료구분
      } else {
        this.ds_search02.feeClsc = '' //수수료구분
      }

      this.ds_tfrchl01 = {}
      this.ds_tfrchl02 = {}
      this.ds_tfrorl01 = {}
      this.ds_tfrorl02 = {}

      // 원금 입금 예정내역
      if (Number(this.ds_search.ftrnAmt) > 0) {
        this.ds_tfrorl01.ftrnAmt = this.ds_detail01.ftrnAmt
        this.ds_tfrorl01.iamtAcnoDepoNm = this.ds_detail01.iamtAccDepoNm
        this.ds_tfrorl01.iamtAcno = this.ds_detail01.iamtAcno
        this.ds_tfrorl01.iamtBkcd = this.ds_detail01.iamtBkcd
        this.ds_tfrorl01.ftrnIamtDate = this.ds_detail01.ftrnIamtDate
      }

      // 수수료입금 예정내역
      if (Number(this.ds_search.feeFtrnAmt) > 0) {
        this.ds_tfrorl02.ftrnAmt = this.ds_detail04.feeFtrnAmt
        this.ds_tfrorl02.iamtAcnoDepoNm = this.ds_detail04.feeIamtAccDepoNm
        this.ds_tfrorl02.iamtAcno = this.ds_detail04.feeIamtAcno
        this.ds_tfrorl02.iamtBkcd = this.ds_detail04.feeIamtBkcd
        this.ds_tfrorl02.ftrnIamtDate = this.ds_detail04.feeFtrnIamtDate
      }

      if (this.ds_search.depositCls == 'FEE') {
        this.ds_search01.prfeChkAmt = this.ds_detail04.chekFeeAmt // 선취수수료수표금액
        this.ds_search01.prfeChkQty = this.ds_detail04.chekFeeIqty // 선취수수료수표수량
        this.ds_search01.prfeFtrnAmt = this.ds_detail04.onlineFeeAmt // 선취수수료이체금액
        this.ds_search01.prfeCashAmt = this.ds_detail04.cashFeeAmt // 선취수수료현금금액

        if (Number(this.ds_detail04.onlineFeeAmt) > 0) {
          this.ds_search02.feeOnlineYn = 'Y' // 온라인수수료입금여부
        } else {
          this.ds_search02.feeOnlineYn = 'N' // 온라인수수료입금여부
        }

        // 차기수수료일자 세팅
        if (this.ds_feeDetail.c == '1') {
          //별납후취해지수수료
          this.ds_search01.nextComsIamtDate = '99991231'
        } else if (this.ds_feeDetail.c == '2') {
          //성과보수
          this.ds_search01.nextComsIamtDate = ''
        } else {
          let vTemp
          // 일반수수료
          if (this.ds_tfaacb.epayPoaoTc == '1') {
            // 선취수수료
            if (this.ds_tfaacb.epayCyclTc == '1') {
              //년
              vTemp = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, 12)
              if (this.ds_detail03.mtrtDate <= vTemp) {
                vTemp = '99991231'
              }
            } else if (this.ds_tfaacb.epayCyclTc == '2') {
              //반기
              vTemp = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, 6)
              if (this.ds_detail03.mtrtDate <= vTemp) {
                vTemp = '99991231'
              }
            } else if (this.ds_tfaacb.epayCyclTc == '3') {
              //분기
              vTemp = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, 3)
              if (this.ds_detail03.mtrtDate <= vTemp) {
                vTemp = '99991231'
              }
            } else if (this.ds_tfaacb.epayCyclTc == '4') {
              //월
              vTemp = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, 1)
              if (this.ds_detail03.mtrtDate <= vTemp) {
                vTemp = '99991231'
              }
            } else {
              vTemp = '99991231'
            }
          } else {
            if (this.ds_tfaacb.epayCyclTc == '1') {
              //년
              vTemp = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, 12)
            } else if (this.ds_tfaacb.epayCyclTc == '2') {
              //반기
              vTemp = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, 6)
            } else if (this.ds_tfaacb.epayCyclTc == '3') {
              //분기
              vTemp = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, 3)
            } else if (this.ds_tfaacb.epayCyclTc == '4') {
              //월
              vTemp = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, 1)
            } else {
              // 처분
              vTemp = '99991231'
            }
          }

          this.ds_search01.nextComsIamtDate = vTemp
        }

        if (this.ds_search.feeDetail != '2') {
          // 현재일자와 수수료 일자가 불일치할경우
          if (this.ds_detail04.feeDate > this.ds_userInfo.busyDate) {
            this.fn_AlertPopup2(0, {
              content: '수수료수납일자가 ' + TSCommUtil.gfn_dateReplace(1, this.ds_detail04.feeDate) + '로 당일보다 미래일자입니다.\n입금처리를 계속 하시겠습니까?',
              confirm: true,
              confirmFunc: this.fn_confirmTrue2
            })
            return false
          }
        }
      }

      this.fn_confirmTrue2()
    },

    /***************************************************************************************
          확인 처리 2
      *****************************************************************************************/
    fn_confirmTrue2() {
      this.ds_tfrchl01 = {}
      this.ds_tfrchl02 = {}

      this.g_eaiId = 'C392_F10600151_S' //insertTFIO40000
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
      this.eaiCommObj.params = this.ds_search01
      this.eaiCommObj.params.tfDeposit = [this.ds_search02] // 기타거래정보
      // this.eaiCommObj.params.tfrchl01  = [this.ds_tfrchl01]   // 원금 수표 내역
      // this.eaiCommObj.params.tfrchl02  = [this.ds_tfrchl02]   // 수수료 수표 내역
      this.eaiCommObj.params.tfrorl01 = [this.ds_tfrorl01] // 원금 입금예정내역
      this.eaiCommObj.params.tfrorl02 = [this.ds_tfrorl02] // 수수료 입금예정내역

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600151, this.fn_error)
    },

    /*=========================================================================
      /* 메소드 명 : fn_admission_call_back(svcid, cd, err_msg)                            
      /* 내용설명  : 입력 후 처리
      /* Return 값 :                                                             
      =========================================================================*/
    async fn_callBack_F10600151(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (resData.errorCode == 'fail.SU050012') {
          this.fn_popup106p()
          return false
        }

        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }

      this.$refs.popup200.fn_Close()

      this.ds_admission = resData
      this.ds_pbpr = resData.passbook

      /* 책임자 승인 */
      if (this.ds_admission.mngAprvSeq > '0') {
        // 승인번호 설정
        this.ds_search.mngAprvSeq = this.ds_admission.mngAprvSeq

        // 온라인입금이 아닐때
        if (Number(this.ds_detail01.onlineIamt) == '0') {
          if (this.ds_search.msUseYn == 'N') {
            this.fn_AlertPopup(0, { content: 'MS정보가 없습니다. 무통장 입금하시겠습니까?', confirm: true, confirmFunc: this.fn_confirmNonAcnoTrue, closeFunc: this.fn_confirmNonAcnoFalse })
          } else {
            // this.fn_userInfoInit () // 사용자정보초기화
            // this.fn_searchAmtInit() // 조회금액항목초기화
            // this.fn_iamtInit     () // 입금금액항목초기화
            // this.fn_feeAmtInit   () // 수수료금액항목초기화
          }
        }

        // 승인요청 팝업 호출
        this.fn_popup106p()
      } else {
        // 통장인쇄
        this.lv_report = null
        if (this.ds_detail03.poaoTc == '1' && this.ds_search.epayPoaoTc == '1' && this.ds_search.depositCls != 'FEE') {
          // 별납이 있는 신규 거래의 경우 리포트 리스트 저장 후 별납 납입 후 전자서명 처리 수랭
          this.lv_report = 'ADD'
          await this.fn_confirmPrintTrue()
          this.fn_checkProcess()
        } else {
          // 프로세스 업무가 아닌 경우 화면 초기화
          if (!this.lv_process) {
            // 추가 입금의 수수료입금의 경우는 리포트를 실행하지 않는다.
            if (this.ds_tfio40000.newAddCls == 'ADD' && this.ds_search.depositCls == 'FEE') {
              this.fn_AlertPopup(0, { content: '수수료 입금처리가 완료되었습니다.', confirm: true, single: true, confirmFunc: this.fn_moveHome })
              // this.fn_userInfoInit () // 사용자정보초기화
              // this.fn_searchAmtInit() // 조회금액항목초기화
              // this.fn_iamtInit     () // 입금금액항목초기화
              // this.fn_feeAmtInit   () // 수수료금액항목초기화
              return
            }
          }

          this.lv_report = ''
          await this.fn_confirmPrintTrue()
          this.fn_checkProcess()
        }
      }
    },

    /***************************************************************************************
          원본 현금금액 입력시 
      *****************************************************************************************/
    edt_cashIamt_onkeyup() {
      this.fn_otxtAmtTot() // 원본입금총액
      this.fn_aftBlctTot() // 신탁금잔 = 원본입금액 + 신탁전잔
    },

    /***************************************************************************************
          원본 입금액 합산 
      *****************************************************************************************/
    fn_otxtAmtTot() {
      this.ds_detail02.iamtTot = Number(this.ds_detail01.cashIamt) + Number(this.ds_detail01.chekIamt) + Number(this.ds_detail01.onlineIamt) // 입금총금액
    },

    /***************************************************************************************
          신탁금잔 합산  
      *****************************************************************************************/
    fn_aftBlctTot() {
      // 입금총금액 = 원본입금액 + 수수료입금액
      this.ds_detail02.aftBlct = Number(this.ds_detail02.iamtTot) + Number(this.ds_detail02.befBlct)
    },

    /***************************************************************************************
          수수료금액 합산  -- 수수료 DIV 에 있는 팝업
      *****************************************************************************************/
    fn_feeSum() {
      this.ds_detail04.feeIamtTot = Number(this.ds_detail04.cashFeeAmt) + Number(this.ds_detail04.chekFeeAmt) + Number(this.ds_detail04.onlineFeeAmt)
    },

    /***********************************************************************************
        수수료구분에 따른 콤포넌트 배치 수정 
      **********************************************************************************/
    fn_FeeComponentState() {
      let deposit_gubun = this.ds_search.depositCls

      this.ds_div.div02 = '1'
      if (deposit_gubun == 'FEE') {
        if (TSCommUtil.gfn_isNull(this.ds_acno.acno) || this.ds_acno.acno.length != 13) {
          this.ds_search.depositCls = this.ds_depositCls[0].value
          TSCommUtil.gfn_validate(this, '계좌번호 입력 하지 않았습니다.')
          this.ds_error.edt_acno = true
          TSCommUtil.gfn_focus(this.$refs['edt_acno'])
          return
        }

        // 수수료 상세황목 표시
        this.ds_feeDetail = []
        this.ds_feeDetail.push({ value: '9', text: '정기별납' })
        this.ds_feeDetail.push({ value: '1', text: '해지별납' })
        //          this.ds_feeDetail.push({value: '2', text: '성과보수'})

        this.ds_search.feeDetail = this.ds_feeDetail[0].value

        this.ds_div.div01 = ''
        this.ds_div.div02 = ''
        this.ds_div.div03 = ''
        this.ds_div.div04 = '1'

        this.ds_search.aprvNo = '0'
        this.ds_detail04.feeDate = ''

        // // 수수료 항목 설정
        this.fn_selectFee()
      } else if (deposit_gubun == 'BRIDGE') {
        if (TSCommUtil.gfn_isNull(this.ds_acno.acno) || this.ds_acno.acno.length != 13) {
          this.ds_search.depositCls = this.ds_depositCls[0].value
          TSCommUtil.gfn_validate(this, '계좌번호 입력 하지 않았습니다.')
          this.ds_error.edt_acno = true
          TSCommUtil.gfn_focus(this.$refs['edt_acno'])
          return
        }

        if (this.ds_acno.brigAccYn != 'Y') {
          this.ds_search.depositCls = this.ds_depositCls[0].value
          return
        }

        // 수수료관련 div 숨김
        this.ds_div.div01 = '1'
        this.ds_div.div02 = '1'
        this.ds_div.div03 = ''
        this.ds_div.div04 = ''

        this.ds_search.aprvNo = '0'
        this.ds_detail04.feeDate = ''

        if (this.ds_acno.trstTypC == '001' || this.ds_acno.trstTypC == '007' || this.ds_acno.trstTypC == '008') {
          // this.Div04.Static03.set_text("입금금액")
        }
      } else if (deposit_gubun == 'ADD') {
        // 추가 입금 시 계약 변경없이 승인 가능
        // this.ds_search.feeDetail.set_visible(false)
      } else {
        //this.ds_search.popProsCls
        this.ds_search.feeDetail = ''

        this.ds_div.div01 = '1'
        this.ds_div.div02 = '1'
        this.ds_div.div03 = ''
        this.ds_div.div04 = ''

        // 계좌번호가 있는경우 에만
        if (!TSCommUtil.gfn_isNull(this.ds_acno.tacno)) {
          /***************************************************************************************
      				입금 입고 금액 조회 
      			*****************************************************************************************/
          this.fn_iamtSearchTr() // 입금 입고 승인신청금액 조회
        }
      }
    },

    /***************************************************************************************
      	수수료 콤보 박스 변경 
      *****************************************************************************************/
    Div01_cbo_feeDetail_onitemchanged() {
      this.fn_selectFee()
    },

    /***************************************************************************************
      	수수료 항목 조회 
      *****************************************************************************************/
    fn_selectFee() {
      if (TSCommUtil.gfn_isNull(this.ds_acno.acno) || this.ds_acno.acno.length != 13) {
        this.ds_search.depositCls = this.ds_depositCls[0].value
        TSCommUtil.gfn_validate(this, '계좌번호 입력 하지 않았습니다.')
        this.ds_error.edt_acno = true
        TSCommUtil.gfn_focus(this.$refs['edt_acno'])
        return
      }

      this.ds_search01 = {}
      this.ds_search01.tacno = this.ds_acno.tacno
      this.ds_search01.bnkbSeq = this.ds_acno.bnkbSeq
      this.ds_search01.accSeq = this.ds_acno.accSeq

      this.g_eaiId = 'C392_F10600155_S' //selectTFIO40000B
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
      this.eaiCommObj.params = this.ds_search01

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600155, this.fn_error)
    },

    /***************************************************************************************
          수수료 항목 조회 후 콜백 
      *****************************************************************************************/
    fn_callBack_F10600155(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }

      this.ds_detailFee = res.data

      this.ds_detail04.feeDate = this.ds_userInfo.busyDate
      this.ds_detail04.feeAdmissAmt_S = this.ds_detailFee.epayFeeAmt
      this.ds_detail04.feeAdmissAmt00 = this.ds_detailFee.epayFeeAmt
      this.ds_detail04.feeAdmissAmt = this.ds_detailFee.epayFeeAmt

      // 수수료 항목 설정
      if (TSCommUtil.gfn_isNull(this.ds_detailFee.nextComsIamtDate)) {
        // 다음수수료일자가 없는 경우
        // 선취별납 수수료의 경우 다음 수수료일자가 없는경우에는 계약일로 표현한다.
        if (this.ds_tfaacb.poaoTc == '1' && this.ds_tfaacb.epayPoaoTc == '1') {
          this.ds_detail04.feeDate = this.ds_tfaacb.contDate
        } else {
          if (this.ds_tfaacb.epayCyclTc == '1') {
            // 년
            this.ds_detail04.feeDate = TSComDate.gfn_addMonth(this.ds_tfaacb.contDate, 12)
          } else if (this.ds_tfaacb.epayCyclTc == '2') {
            //반기
            this.ds_detail04.feeDate = TSComDate.gfn_addMonth(this.ds_tfaacb.contDate, 6)
          } else if (this.ds_tfaacb.epayCyclTc == '3') {
            //분기
            this.ds_detail04.feeDate = TSComDate.gfn_addMonth(this.ds_tfaacb.contDate, 3)
          } else if (this.ds_tfaacb.epayCyclTc == '4') {
            //월
            this.ds_detail04.feeDate = TSComDate.gfn_addMonth(this.ds_tfaacb.contDate, 1)
          } else {
            //처분 겨냥(처분의 경우 NextFeeDt없음)
            this.ds_detail04.feeDate = this.ds_search.bizDate
          }
        }
      } else if (this.ds_detailFee.nextComsIamtDate == '99991231') {
        if (this.ds_search.depositCls != '2') {
          this.fn_AlertPopup(0, { content: '모든 수수료를 납부 하셨습니다.', confirm: true, confirmFunc: this.fn_dsDetail04Clear, single: true })
          // this.ds_detail04.feeAdmissAmt_S = "0"
          // this.ds_detail04.feeAdmissAmt   = "0"
          // this.ds_detail04.feeAdmissAmt00 = "0"
          // this.ds_detail04.addFee         = "0"

          // this.fn_confirmPrintTrue()
          // this.fn_checkProcess("end")

          return
        }
      } else {
        this.ds_detail04.feeDate = this.ds_detailFee.nextComsIamtDate
      }

      // 수수료 재계산
      if (this.ds_detail04.feeDate > this.ds_tfaacb.mtrtDate && this.ds_search.feeDetail != '1') {
        // 별납후취해지수수료가 아닐때.
        let vDif = 0
        let vFeeAmt = 0
        let vFeeDt = this.ds_tfaacb.mtrtDate
        if (this.ds_tfaacb.epayCyclTc == '1') {
          //년
          vDif = 1
        } else if (this.ds_tfaacb.epayCyclTc == '2') {
          //반기
          vDif = 2
        } else if (this.ds_tfaacb.epayCyclTc == '3') {
          //분기
          vDif = 4
        } else if (this.ds_tfaacb.epayCyclTc == '4') {
          //월
          vDif = 12
        }
        var vPrevFee_dt = TSComDate.gfn_addMonth(this.ds_detail04.feeDate, -12 / vDif) // 전기 수수료일 계산

        //재계산한 수수료일이 계약일보다 작으면 계약일 설정
        if (vPrevFee_dt < this.ds_tfaacb.contDate) {
          vPrevFee_dt = this.ds_tfaacb.contDate
        }

        vFeeAmt = Number(this.ds_detailFee.epayFeeAmt) * (TSComDate.gfn_getDiffDay(vPrevFee_dt, vFeeDt) / TSComDate.gfn_getDiffDay(vPrevFee_dt, this.ds_detail04.feeDate))

        this.ds_detail03.contAmt = Math.round(vFeeAmt)
        this.ds_detail04.feeAdmissAmt = Math.round(vFeeAmt)
        this.ds_detail04.feeAdmissAmt_S = Math.round(vFeeAmt) // 수수료금액
        this.ds_detail04.feeAdmissAmt00 = Math.round(vFeeAmt)

        this.ds_detail04.feeDate = this.ds_tfaacb.mtrtDate
      }

      if (this.ds_search.feeDetail == '1') {
        // 별납후취해지수수료
        if (!(this.ds_tfaacb.poaoTc == '1' && this.ds_tfaacb.epayPoaoTc == '2')) {
          this.fn_AlertPopup(0, { content: '별납후취 계좌가 아닙니다.', confirm: true, confirmFunc: this.fn_dsDetail04Clear, single: true })
          // this.ds_detail04.feeAdmissAmt_S = "0"
          // this.ds_detail04.feeAdmissAmt   = "0"
          // this.ds_detail04.feeAdmissAmt00 = "0"
          // this.ds_detail04.addFee         = "0"
          return
        }

        let haejiFeeAmt = Number(this.ds_detailFee.epayFeeAmt)
        let vDiff = 0
        let vDiffSttDt = ''

        if (this.ds_tfaacb.epayCyclTc == '1') {
          //년
          vDiff = 1
        } else if (this.ds_tfaacb.epayCyclTc == '2') {
          //반기
          vDiff = 2
        } else if (this.ds_tfaacb.epayCyclTc == '3') {
          //분기
          vDiff = 4
        } else if (this.ds_tfaacb.epayCyclTc == '4') {
          //월
          vDiff = 12
        }
        let vNextFeeDt = this.ds_detail04.feeDate

        let cnt = 0
        while (vNextFeeDt < this.ds_search.bizDate) {
          vNextFeeDt = TSComDate.gfn_addMonth(vNextFeeDt, 12 / vDiff)
          cnt = cnt + 1
        }

        vDiffSttDt = TSComDate.gfn_minusMonth(vNextFeeDt.toString(), 12 / vDiff)
        haejiFeeAmt = Number(this.ds_tfaacb.epayFeeAmt) * cnt

        // 별납후취수수료 입금 보완의 건
        haejiFeeAmt = haejiFeeAmt + Number(this.ds_tfaacb.epayFeeAmt) * (TSComDate.gfn_getDiffDay(vDiffSttDt, this.ds_search.bizDate) / TSComDate.gfn_getDiffDay(vDiffSttDt, vNextFeeDt))

        if (haejiFeeAmt < 0) {
          haejiFeeAmt = 0
        }

        haejiFeeAmt = Math.round(haejiFeeAmt)

        this.ds_detail03.contAmt = haejiFeeAmt
        this.ds_detail04.feeAdmissAmt = haejiFeeAmt
        this.ds_detail04.feeAdmissAmt_S = haejiFeeAmt // 수수료금액
        this.ds_detail04.feeAdmissAmt00 = haejiFeeAmt
        this.ds_detail04.feeDate = this.ds_search.bizDate

        if (haejiFeeAmt <= 0) {
          TSCommUtil.gfn_validate(this, '처리할 별납후취해지수수료 금액이 없습니다.')
          //        		this.fn_AlertPopup(0,{content : "처리할 별납후취해지수수료 금액이 없습니다."})
          return
        }
      } else if (this.ds_search.feeDetail == '2') {
        // 성과보수
        if (this.ds_detailFee.rufeYn == 'N' || this.ds_detailFee.rufeYn == '') {
          this.fn_AlertPopup(0, { content: '성과보수 상품이 아닙니다.', confirm: true, confirmFunc: this.fn_dsDetail04Clear, single: true })
          // this.ds_detail04.feeAdmissAmt_S = "0"
          // this.ds_detail04.feeAdmissAmt   = "0"
          // this.ds_detail04.feeAdmissAmt00 = "0"
          // this.ds_detail04.addFee         = "0"
          return
        }
        this.ds_detail04.feeDate = this.ds_search.bizDate
        this.ds_detail04.feeAdmissAmt_S = '0'
        this.ds_detail04.feeAdmissAmt = '0'
        this.ds_detail04.feeAdmissAmt00 = '0'
        this.ds_detail04.addFee = '0'
      }

      // 부동산펀드의 경우 부가세 표시
      if (this.ds_acno.trstTypC == '003') {
        let apprDate = this.ds_5165.filter((p) => p.value == 'DATE')[0].text
        let apprRt = this.ds_5165.filter((p) => p.value == 'RT')[0].text

        if (this.ds_tfaacb.contDate >= apprDate) {
          this.ds_detail04.addFee = Math.round(Number(this.ds_detail04.feeAdmissAmt) * Number(apprRt))
        } else {
          this.ds_detail04.addFee = '0'
        }

        let totFee = Number(this.ds_detail04.feeAdmissAmt) + Number(this.ds_detail04.addFee)
        this.ds_detail04.feeAdmissAmt_S = totFee // 수수료금액
        this.ds_detail04.feeAdmissAmt00 = totFee
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 원본금액 온라인 입금처리 / 1: 수수료 온라인 입금처리
     ******************************************************************************/
    fn_OpenPopup(type) {
      switch (type) {
        case '205pA':
          this.fn_popup205pA()
          break
        case '205pB':
          this.fn_popup205pB()
          break
        case '206p':
          this.fn_popup206p()
          break
        case '106p':
          this.fn_popup106p()
          break
        case '510p':
          this.fn_popup510p()
          break
      }
    },

    /***************************************************************************************
          원본금액 온라인 입금처리 팝업 호출
      *****************************************************************************************/
    fn_popupMSPTS206P() {},

    /***************************************************************************************
          원본금액 온라인 입금처리 팝업 호출
      *****************************************************************************************/
    fn_popup205pA() {
      // 계좌번호 입력 체크
      if (TSCommUtil.gfn_isNull(this.ds_acno.acno) || this.ds_acno.acno.length != 13) {
        TSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다.')
        this.ds_error.edt_acno = true
        TSCommUtil.gfn_focus(this.$refs['edt_acno'])
        return false
      }

      // 온라인 금액항목 초기화
      this.ds_detail01.onlineIamt = '0'
      this.ds_detail01.linkAcctProcAmt = '0'

      // 사전등록데이터 항목
      this.ds_search.ftrnAmt = '0'
      this.ds_search.iamtAccDepoNm = ''
      this.ds_search.iamtAcno = ''
      this.ds_search.iamtBkcd = ''
      this.ds_search.ftrnIamtDate = ''

      this.pPopup200Obj.lcTacno = this.ds_tfaacb.tacno
      this.pPopup200Obj.lcBnkbSeq = this.ds_tfaacb.bnkbSeq
      this.pPopup200Obj.lcAccSeq = this.ds_tfaacb.accSeq
      this.pPopup200Obj.csNm = this.ds_tfaacb.cnm
      this.pPopup200Obj.lcfeeWonbonGb = '1'
      this.pPopup200Obj.reqAmt = this.ds_detail01.admissAmt_S
      this.$refs.popup200.fn_Open()
    },

    /***************************************************************************************
          수수료 온라인 입금처리 팝업 호출  -- 수수료 DIV 에 있는 팝업 
      *****************************************************************************************/
    fn_popup205pB() {
      // 계좌번호 입력 체크
      if (TSCommUtil.gfn_isNull(this.ds_acno.acno) || this.ds_acno.acno.length != 13) {
        TSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다.')
        this.ds_error.edt_acno = true
        TSCommUtil.gfn_focus(this.$refs['edt_acno'])
        return false
      }

      // 수수료금액항목 초기화
      this.ds_detail04.onlineFeeAmt = '0'
      this.ds_detail04.linkAcctFeeAmt = '0'

      // 사전등록 수수료 데이터 항목
      this.ds_detail04.feeFtrnAmt = '0'
      this.ds_detail04.feeIamtAccDepoNm = ''
      this.ds_detail04.feeIamtAcno = ''
      this.ds_detail04.feeIamtBkcd = ''
      this.ds_detail04.feeFtrnIamtDate = ''

      this.pPopup200Obj.lcTacno = this.ds_tfaacb.tacno
      this.pPopup200Obj.lcBnkbSeq = this.ds_tfaacb.bnkbSeq
      this.pPopup200Obj.lcAccSeq = this.ds_tfaacb.accSeq
      this.pPopup200Obj.csNm = this.ds_tfaacb.cnm
      this.pPopup200Obj.lcfeeWonbonGb = '2'
      this.pPopup200Obj.reqAmt = this.ds_detail04.feeAdmissAmt00
      this.pPopup200Obj.poaoTc = this.ds_tfaacb.poaoTc
      this.pPopup200Obj.contDate = this.ds_tfaacb.contDate

      this.$refs.popup200.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack_200P
     * 설명       : 온라인입금 popup 컴포넌트에서 callback
     * 타입(type) : 0: 원본금액 온라인 입금처리 / 1: 수수료 온라인 입금처리
     ******************************************************************************/
    fn_Popup_CallBack_200P(pData) {
      switch (pData.lcfeeWonbonGb) {
        case '1':
          // 입금금액이 존재하는 경우
          if (!TSCommUtil.gfn_isNull(pData.orginAmt)) {
            this.ds_detail01.onlineIamt = pData.orginAmt // 온라인입금금액
            this.ds_detail01.linkAcctProcAmt = pData.orginAmt // 가상계좌처리금액
          }

          this.fn_otxtAmtTot() // 원본입금총액
          this.fn_aftBlctTot() // 신탁금잔 = 원본입금액 + 신탁전잔
          break
        case '2':
          // 수수료입금금액이 존재하는 경우
          if (!TSCommUtil.gfn_isNull(pData.fee)) {
            this.ds_detail04.onlineFeeAmt = pData.fee // 수수료온라인입금금액
            this.ds_detail04.linkAcctFeeAmt = pData.fee // 수수료가상계좌처리금액
          }

          this.fn_feeSum() // 수수료입금총액
          break
      }

      this.fn_confirm()
    },

    /***************************************************************************************
          고객계좌정보 조회
      *****************************************************************************************/
    fn_popup206p() {
      this.$refs.popup206.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack_206P
     * 설명       : popup 컴포넌트에서 callback
     ******************************************************************************/
    fn_Popup_CallBack_206P(pData) {
      this.ds_searchAc.inAcno = pData.tacno + pData.bnkbSeq
      this.ds_searchAc.accSeq = pData.accSeq
      this.fn_searchAcno()
    },

    /***************************************************************************************
          책임자 승인요청 팝업 호출
      *****************************************************************************************/
    fn_popup106p() {
      // 1. 승인화면 캡쳐(일자+지점+승인번호로 파일명을 생성한다.)
      let fileName = this.ds_userInfo.busyDate + this.ds_userInfo.brcd + this.ds_admission.mngAprvSeq + '.png'
      //          let bSucc    = this.saveToImageFile(fileName, "PNG")

      this.pPopup106Obj.pProcDate = this.ds_userInfo.busyDate
      this.pPopup106Obj.pBrcd = this.ds_userInfo.brcd
      this.pPopup106Obj.pMngAprvSeq = this.ds_admission.mngAprvSeq
      this.pPopup106Obj.pFileName = fileName
      this.pPopup106Obj.pReqEmno = this.ds_userInfo.usid

      this.$refs.popup106.fn_Open(this.pPopup106Obj)
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack_106P
     * 설명       : 승인요청 popup 컴포넌트에서 callback
     ******************************************************************************/
    fn_Popup_CallBack_106P(pData) {
      if (TSCommUtil.gfn_isNull(pData)) {
        this.ds_search.mngAprvSeq = '0'
        return
      }
      this.fn_confirm()
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content
            this.pAlertPopupObj.contentTitle = ''
            this.pAlertPopupObj.confirm = false
            this.pAlertPopupObj.cancel = true
            this.pAlertPopupObj.type = 0

            if (!TSCommUtil.gfn_isNull(pPopupObj.confirm)) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
            } else {
              this.pAlertPopupObj.confirm = ''
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.confirmFunc)) {
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            } else {
              this.pAlertPopupObj.confirmFunc = ''
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.closeFunc)) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
            } else {
              this.pAlertPopupObj.closeFunc = ''
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.single)) {
              this.pAlertPopupObj.single = pPopupObj.single
            } else {
              this.pAlertPopupObj.single = ''
            }
          }
          break
        case 7:
        case 9:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content
            this.pAlertPopupObj.contentTitle = ''
            this.pAlertPopupObj.confirm = false
            this.pAlertPopupObj.cancel = true
            this.pAlertPopupObj.type = 0

            if (!pPopupObj.cancel) {
              this.pAlertPopupObj.cancel = pPopupObj.cancel
            }
            if (pPopupObj.confirm) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              this.pAlertPopupObj.confirmData = pPopupObj.confirmData
            }
            if (pPopupObj.closeFunc) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
              this.pAlertPopupObj.closeData = pPopupObj.closeData
            }
            if (!TSCommUtil.gfn_isNull(type) && type != 0) {
              this.pAlertPopupObj.type = type
            }
            if (pPopupObj.contentTitle) {
              this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
            }
          }
          break
      }

      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup2(type, pPopupObj) {
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj2.content = pPopupObj.content

            if (!TSCommUtil.gfn_isNull(pPopupObj.confirm)) {
              this.pAlertPopupObj2.confirm = pPopupObj.confirm
            } else {
              this.pAlertPopupObj2.confirm = ''
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.confirmFunc)) {
              this.pAlertPopupObj2.confirmFunc = pPopupObj.confirmFunc
            } else {
              this.pAlertPopupObj2.confirmFunc = ''
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.closeFunc)) {
              this.pAlertPopupObj2.closeFunc = pPopupObj.closeFunc
            } else {
              this.pAlertPopupObj2.closeFunc = ''
            }
          }
          break
      }

      this.$refs.alertPopup2.fn_Open()
    },

    // 팝업 확인, 닫기 event
    returnEvt(value) {},

    // 팝업 확인, 닫기 event
    returnEvt2(value) {},

    /***************************************************************************************
          계좌번호 조회
      *****************************************************************************************/
    fn_searchAcno() {
      if (TSCommUtil.gfn_trim(this.ds_searchAc.inAcno).length != 10) {
        return false
      }

      this.ds_acno = {} // 계좌정보
      this.ds_acnoList = [] // 계좌정보List

      this.ds_searchAc.tacno = this.ds_searchAc.inAcno.substring(0, 7)
      this.ds_searchAc.bnkbSeq = this.ds_searchAc.inAcno.substring(7, 10)
      this.ds_searchAc.accStatC = '11'
      this.ds_searchAc.brigAccYn = ''

      this.g_eaiId = 'C392_F10600245_S' // selectTPUP95090List
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
      this.eaiCommObj.params = this.ds_searchAc

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600246, this.fn_error)
    },

    /***************************************************************************************
          계좌번호 조회 후 콜백  
      *****************************************************************************************/
    fn_callBack_F10600246(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }

      this.ds_acnoList = resData.tpup95090

      if (this.ds_acnoList.length > 0) {
        for (let i = 0; i < this.ds_acnoList.length; i++) {
          this.ds_acnoList[i].acno = this.ds_acnoList[i].tacno + this.ds_acnoList[i].bnkbSeq + this.ds_acnoList[i].accSeq
          this.ds_acnoList[i].value = this.ds_acnoList[i].accSeq
          this.ds_acnoList[i].text = this.ds_acnoList[i].gdNm
        }

        if (this.lv_procInfo != null && this.lv_procInfo.accSeq != null) {
          this.ds_acno = this.ds_acnoList.filter((p) => p.accSeq == this.lv_procInfo.accSeq)[0]
          this.ds_searchAc.accSeq = this.lv_procInfo.accSeq
        } else if (!TSCommUtil.gfn_isNull(this.ds_searchAc.accSeq)) {
          this.ds_acno = this.ds_acnoList.filter((p) => p.accSeq == this.ds_searchAc.accSeq)[0]
          if (this.ds_acno == undefined) {
            this.ds_acno = {}
            TSCommUtil.gfn_validate(this, '계좌일련번호는 필수입력 항목입니다.')
            this.ds_error.edt_acno = true
            TSCommUtil.gfn_focus(this.$refs['edt_acno'])
            return
          }
        } else {
          this.ds_acno = this.ds_acnoList[0]
          this.ds_searchAc.accSeq = this.ds_acnoList[0].accSeq
        }

        this.fn_searchTr()
      }
    },

    /***************************************************************************************
          계좌번호 변경  
      *****************************************************************************************/
    fn_changeAcno(value, el) {
      this.ds_acno = this.ds_acnoList.filter((p) => p.accSeq == this.ds_searchAc.accSeq)[0]
      this.fn_searchTr()
    },

    /******************************************************************************
     * Function명 : fn_confirmNonAcnoTrue, fn_confirmNonAcnoFalse
     * 설명       : MS정보가 없습니다. 무통장 입금하시겠습니까?
     ******************************************************************************/
    fn_confirmNonAcnoTrue() {
      this.fn_popup106p()
    },

    /******************************************************************************
     * Function명 : fn_confirmNonAcnoFalse
     ******************************************************************************/
    fn_confirmNonAcnoFalse() {
      this.fn_userInfoInit() // 사용자정보초기화
      this.fn_searchAmtInit() // 조회금액항목초기화
      this.fn_iamtInit() // 입금금액항목초기화
      this.fn_feeAmtInit() // 수수료금액항목초기화

      TSCommUtil.gfn_focus(this.$refs['edt_acno'])
      return false
    },

    /******************************************************************************
     * Function명 : fn_confirmCommissionTrue, fn_confirmCommissionFalse
     * 설명       : 해당 계약은 별납선취 수수료를 당일 납부하여야 합니다.\n수수료입금 구분을 선택하여 입금처리 바랍니다.
     ******************************************************************************/
    fn_confirmCommissionTrue() {
      this.fn_iamtInit() // 입금금액항목초기화
      this.fn_feeAmtInit() // 수수료금액항목초기화

      this.ds_search.depositCls = this.ds_depositCls[1].value
      this.fn_FeeComponentState()
    },

    /******************************************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************************************/
    fn_checkProcess() {
      let lv_Vm = this
      let tmpContentTitle = '입금완료'
      let tmpContent = []

      let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
      let basInfo = lv_Vm.getStore('tsStore').getters.getBasInfo.data
      let isLast = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)
      let t_type = 0

      if (TSCommUtil.gfn_isNull(this.lv_report)) {
        tmpContent.push('신탁 입금이 완료되었습니다.')
      }
      if (this.lv_report == 'ADD') {
        tmpContent.push('해당 계약은 별납선취 수수료를 당일 납부하여야 합니다.')
        tmpContent.push('수수료입금 구분을 선택하여 입금처리 바랍니다.')
      }

      if (isProcess) {
        if (basInfo.procTyp === 'custInfoReg') {
          if (this.lv_report == 'ADD') {
          } else {
            tmpContent.push('송금계좌등록 화면으로 이동합니다.')
          }
          t_type = 7
        }
        if (basInfo.procTyp === 'trsf') {
          t_type = 9
        }
      } else {
        // 프로세스 진행 중이 아닐 경우
        if (this.lv_report == 'ADD') {
          this.getStore('tsStore').dispatch('IS_PROCUNIT_START') // 단위화면 프로세스 여부 설정
          t_type = 7
        } else {
          t_type = 9
        }
      }

      let t_popupObj = {
        confirm: true,
        confirmFunc: this.fn_searchReport,
        confirmData: {
          tacno: this.ds_acno.tacno,
          bnkbSeq: this.ds_acno.bnkbSeq,
          accSeq: this.ds_acno.accSeq,
          cvrTypC: this.ds_tfio40000.newAddCls
        },
        content: tmpContent,
        contentTitle: tmpContentTitle
      }

      lv_Vm.fn_AlertPopup(t_type, t_popupObj)
    },

    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue() {
      this.ds_searchReportList = []
      this.ds_searchParam = {}

      this.ds_searchParam.tacno = this.ds_acno.tacno
      this.ds_searchParam.bnkbSeq = this.ds_acno.bnkbSeq
      this.ds_searchParam.accSeq = this.ds_acno.accSeq
      this.ds_searchParam.ctno = Number(this.ds_acno.accSeq)
      this.ds_searchParam.procType = 'PP'
      this.ds_searchParam.signYn = 'N'

      // 전자서명 동의서
      this.ds_searchReport.reportMrdNm = 'TS000001'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      if (this.ds_tfio40000.newAddCls == 'ADD') {
        this.ds_searchParam = {}
        this.ds_searchParam.tacno = this.ds_acno.tacno
        this.ds_searchParam.bnkbSeq = this.ds_acno.bnkbSeq
        this.ds_searchParam.accSeq = this.ds_acno.accSeq
        this.ds_searchParam.csId = this.ds_acno.csId

        let mappingData = {
          cnm: this.ds_acno.cnm,
          aprvBusnTc1: '1031',
          teleVssTc: '1',
          addmIamt: this.ds_detail02.iamtTot
        }

        //this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC22100.mrd" //신탁계약변경신청서
        this.ds_searchReport.reportMrdNm = 'TS000055' //신탁계약변경신청서
        this.ds_searchReport.reportUrl = 'reportTFAC20000C'
        //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReport.mappingData = JSON.stringify(mappingData)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (this.ds_tfio40000.newAddCls == 'NEW') {
        // 계좌정보 조회
        let acnoInfo = await TSInfoUtil.gfn_getAcnoInfo(this, this.ds_searchParam)
        console.log('acnoInfo : ', acnoInfo)

        let tfac20000Rst = acnoInfo.tfac20000[0]
        let tfac20000Cs = acnoInfo.tfac20000[1]

        if (this.ds_acno.trstTypC != '002' && this.ds_acno.trstTypC != '003') {
          // 유가증권신탁, 부동산신탁이 아닌경우
          //필수 : TS000037_8.신탁 상설핵설  교부이해확인서
          this.ds_searchReport.reportMrdNm = 'TS000037'
          this.ds_searchReport.reportUrl = 'reportTFAC20000'
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        //상품설명서 상품코드(상품코드:gdC)로 맵핑(그 외 상품은 설명서 없음)
        if (tfac20000Rst.gdC == 'A25188') {
          // A25188 :TS000008_리치플랜하나금융지주56-2
          this.ds_searchReport.reportMrdNm = 'TS000008'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25189') {
          // A25189:TS000009_리치플랜 신한은행채권
          this.ds_searchReport.reportMrdNm = 'TS000009'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25190') {
          // A25190:TS000010_리치플랜_국고채(19-08)
          this.ds_searchReport.reportMrdNm = 'TS000010'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A41036') {
          // A41036:TS000011_리치플랜 예금
          this.ds_searchReport.reportMrdNm = 'TS000011'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A11004') {
          // A11004:TS000012_리치플랜MMT(G)
          this.ds_searchReport.reportMrdNm = 'TS000012'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25152') {
          // A25152:TS000013_마이크로소프트  회사채(2047년)
          this.ds_searchReport.reportMrdNm = 'TS000013'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25173') {
          // A25173:TS000014_마이크로소프트  회사체(2050년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000014'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25185') {
          // A25185:TS000015_버라이즌  회사채(2031년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000015'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25142') {
          // A25142:TS000016_버라이즌  회사채(2049년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000016'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25182') {
          // A25182:TS000017_아마존  회사채(2030년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000017'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25154') {
          // A25154:TS000018_아마존  회사채(2047년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000018'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25180') {
          // A25180:TS000019_애플 회사채(2031년  만기)
          this.ds_searchReport.reportMrdNm = 'TS000019'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25146') {
          // A25146:TS000020_애플 회사채(2049년  만기)
          this.ds_searchReport.reportMrdNm = 'TS000020'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25181') {
          // A25181:TS000021_인텔 회사채(2030년  만기)
          this.ds_searchReport.reportMrdNm = 'TS000021'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25153') {
          // A25153:TS000022_인텔 회사채(2049년  만기)
          this.ds_searchReport.reportMrdNm = 'TS000022'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25148') {
          // A25148:TS000023_미국채   10년(2029년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000023'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25191') {
          // A25191:TS000061_리치플랜국고채(19-06)
          this.ds_searchReport.reportMrdNm = 'TS000061'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25192') {
          // A25192:TS000062_리치플랜국고채(21-09)
          this.ds_searchReport.reportMrdNm = 'TS000062'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25193') {
          // A25193:TS000063_리치플랜국고채(22-14)
          this.ds_searchReport.reportMrdNm = 'TS000063'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25197') {
          // A25197:TS000064_리치플랜국고채(16-06)
          this.ds_searchReport.reportMrdNm = 'TS000064'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25198') {
          // A25198:TS000065_리치플랜국고채(20-07)
          this.ds_searchReport.reportMrdNm = 'TS000065'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25199') {
          // A25199:TS000066_$미국국채(2024)
          this.ds_searchReport.reportMrdNm = 'TS000066'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25200') {
          // A25200:TS000067_$미국국채(2043)
          this.ds_searchReport.reportMrdNm = 'TS000067'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25201') {
          // A25201:TS000068_$미국국채(2053)
          this.ds_searchReport.reportMrdNm = 'TS000068'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45100') {
          // A45100:TS000070_KODEX 2차전지산업 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000070'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45103') {
          // A45103:TS000071_KODEX 코스닥150 ETF
          this.ds_searchReport.reportMrdNm = 'TS000071'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45102') {
          // A45102:TS000072_KODEX200 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000072'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45101') {
          // A45101:TS000073_TIGER미국테크TOP10 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000073'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25211') {
          // A25211:TS000074_리치플랜대신ELB91회
          this.ds_searchReport.reportMrdNm = 'TS000074'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45104') {
          // A45104:TS000075_T-미국P반도체 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000075'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45105') {
          // A45105:TS000076_K-종합B 액티브 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000076'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45106') {
          // A45106:TS000077_K-단기채권 PLUS ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000077'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45107') {
          // A45107:TS000078_T-미국나스닥100 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000078'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45108') {
          // A45108:TS000079_T-미국S&P500 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000079'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45109') {
          // A45109:TS000080_A-고배당주 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000080'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45110') {
          // A45110:TS000081_T-200 IT ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000081'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45111') {
          // A45111:TS000082_S-미국배당 DJ. ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000082'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25212') {
          // A25212:TS000083_리치플랜대신ELB92회
          this.ds_searchReport.reportMrdNm = 'TS000083'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45112') {
          // A45112:TS000088_T-MSCI Korea TR ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000088'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45113') {
          // A45113:TS000089_T-TOP10 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000089'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45114') {
          // A45114:TS000090_K-은행 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000090'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45115') {
          // A45115:TS000091_K-자동차 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000091'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45116') {
          // A45116:TS000092_K-반도체 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000092'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45117') {
          // A45117:TS000093_K-국고채 30년 액티브 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000093'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45118') {
          // A45118:TS000094_T-200 중공업 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000094'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45119') {
          // A45119:TS000095_T-차이나항셍테크 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000095'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45120') {
          // A45120:TS000086_K-26-12회사채A.ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000086'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45121') {
          // A45121:TS000084_T-24-10회사채(A+이상)A.ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000084'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45122') {
          // A45122:TS000085_T-25-10회사채(A+이상)A.ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000085'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45123') {
          // A45123:TS000087_H-24-09 회사채(AA-이상) A. ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000087'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25213') {
          // A25213:TS000096_리치플랜대신ELB93회
          this.ds_searchReport.reportMrdNm = 'TS000096'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25217') {
          // A25218:TS000098_리치플랜 네이버4-2
          this.ds_searchReport.reportMrdNm = 'TS000098'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25218') {
          // A25218:TS000099_리치플랜서울도철23-06
          this.ds_searchReport.reportMrdNm = 'TS000099'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // ASR240300119 신규신탁 상품설명서 전자서식 매핑 2024.03.06

        if (tfac20000Rst.gdC == 'A25219') {
          // A25219:TS000100_$미국국채 0.375_2709
          this.ds_searchReport.reportMrdNm = 'TS000100'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25220') {
          // A25220:TS000101_$미국국채 0.5_2710
          this.ds_searchReport.reportMrdNm = 'TS000101'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25221') {
          // A25221:TS000102_$미국국채 0.625_3008
          this.ds_searchReport.reportMrdNm = 'TS000102'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25222') {
          // A25222:TS000103_리치플랜 국고채(23-10)
          this.ds_searchReport.reportMrdNm = 'TS000103'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25223') {
          // A25223:TS000104_리치플랜 국고채(23-6)
          this.ds_searchReport.reportMrdNm = 'TS000104'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25224') {
          // A25224:TS000105_리치플랜 국고채(21-2)
          this.ds_searchReport.reportMrdNm = 'TS000105'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // ASR240300879 신규신탁 상품설명서 전자서식 매핑 2024.03.25

        if (tfac20000Rst.gdC == 'A45124') {
          // A45124:TS000108_S-초단기채권 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000108'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45125') {
          // A45125:TS000109_S-반도체소부장 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000109'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45126') {
          // A45126:TS000110_A-미국채30년(H)ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000110'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45127') {
          // A45127:TS000111_K-선진국MSCI ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000111'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45128') {
          // A45128:TS000112_K-K로봇액티브 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000112'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45129') {
          // A45129:TS000113_T-현대차그룹 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000113'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // 2024.08.06 ASR240701107_상품설명서 갱신에 따른 태블릿 전자서식 수정

        // 고령자여부
        if (this.fn_lifeAge(this.ds_acno.rcno) >= this.oldAge) {
          // 고령자

          //상품등급 : ivRskRatingC : 1~6
          if (tfac20000Rst.ivRskRatingC == '1') {
            // 고령자/상품등급1 : TS000031_(개정_고령자1등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000031'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '2') {
            // 고령자/상품등급2 : TS000032_(개정_고령자2등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000032'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '3') {
            // 고령자/상품등급3 : TS000033_(개정_고령자3등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000033'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '4') {
            // 고령자/상품등급4 : TS000034_(개정_고령자4등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000034'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '5') {
            // 고령자/상품등급5 : TS000035_(개정_고령자5등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000035'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '6') {
            // 고령자/상품등급6 : TS000036_(개정_고령자6등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000036'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          }
        } else {
          // 일반

          //상품등급 : ivRskRatingC : 1~6
          if (tfac20000Rst.ivRskRatingC == '1') {
            // 일반/상품등급1 : TS000025_1등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000025'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '2') {
            // 일반/상품등급2 : TS000026_2등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000026'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '3') {
            // 일반/상품등급3 : TS000027_3등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000027'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '4') {
            // 일반/상품등급4 : TS000028_4등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000028'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '5') {
            // 일반/상품등급5 : TS000029_5등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000029'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '6') {
            // 일반/상품등급6 : TS000030_6등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000030'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          }
        }

        // 해외상품여부 : frBondCpTc
        if (!TSCommUtil.gfn_isNull(tfac20000Rst.frBondCpTc)) {
          // 해외상품여부 Y

          // TS000038_외화채권 특정금전신탁  위험고지 확인서
          this.ds_searchReport.reportMrdNm = 'TS000038'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          // TS000024_필수_개인(신용)정보  처리동의서_(해외채권 신탁용)
          this.ds_searchReport.reportMrdNm = 'TS000024'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        // 계약서 리스트
        this.ds_searchReport.reportMrdNm = ''
        this.ds_searchReport.reportUrl = 'reportTFAC20000'

        if (this.ds_acno.trstTypC == '001') {
          // 특전금전신탁
          if (this.ds_tfaacb.gdTypDtlC == 'A') {
            // 생전증여
            this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
            this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20007.mrd'
          } else if (this.ds_tfaacb.gdTypDtlC == 'B') {
            // 특별부양신탁
            this.ds_searchParam.signYn = 'N'
            this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20008.mrd'
          } else if (this.ds_tfaacb.gdTypDtlC == 'E') {
            // ISA
            this.ds_searchParam.signYn = 'N'
            this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20009.mrd'
          } else {
            //특금 그외
            this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
            // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20000.mrd"
            this.ds_searchReport.reportMrdNm = 'TS000047' // TS000047_2108(삼성생명신탁)특정금전신탁 계약서 개정(시행_20210818)
          }
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          if (this.ds_tfaacb.gdTypDtlC == 'E') {
            // ISA
            this.ds_searchParam.signYn = 'N'
            this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20005.mrd'
          } else {
            this.ds_searchParam.signYn = 'N'
            // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20001.mrd"
            this.ds_searchReport.reportMrdNm = 'TS000052' // TS000052_특정금전신탁 운용지시서
          }
          this.ds_searchParam.procType = ''
          this.ds_searchParam.signYn = 'N'
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.ds_acno.trstTypC == '008') {
          //유언대용신탁(금전)  20230425
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20019.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000048' // TS000048_2301(삼성생명)Whole Life신탁 특약 개정(시행_230126)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20000.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000047' // TS000047_2108(삼성생명신탁)특정금전신탁 계약서 개정(시행_20210818)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.signYn = 'N'
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20001.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000052' // TS000052_특정금전신탁 운용지시서
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.ds_acno.trstTypC == '009') {
          //유언대용신탁(유가증권)  20230425
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20019.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000048' // TS000048_2301(삼성생명)Whole Life신탁 특약 개정(시행_230126)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20020.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000049' // TS000049_2301(삼성생명)유가증권신탁 계약서 개정(시행_230126)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.ds_acno.trstTypC == '010') {
          //유언대용신탁(부동산)  20230425
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20019.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000048' //TS000048_2301(삼성생명)Whole Life신탁 특약 개정(시행_230126)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20021.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000050' // TS000050_2301(삼성생명)을종부동산관리신탁 계약서 개정(시행_230126)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.ds_acno.trstTypC == '002') {
          //유가증권신탁
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20011.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000049' // TS000050_2301(삼성생명)을종부동산관리신탁 계약서 개정(시행_230126)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.ds_acno.trstTypC == '003') {
          //재산신탁
          //arg += " report="	+ quote("RPT::R_AC2002.xml")
          this.ds_searchParam.signYn = 'N'
          // this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20012.mrd"
          this.ds_searchReport.reportMrdNm = 'TS000050' // TS000050_2301(삼성생명)을종부동산관리신탁 계약서 개정(시행_230126)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.ds_acno.trstTypC == '004') {
          //금전채권신탁
          //arg += " report=" + quote("RPT::R_AC2003.xml")
          this.ds_searchParam.signYn = 'N'
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20030.mrd'
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.ds_acno.trstTypC == '007') {
          // 2021-08-31 add 치매신탁
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20018.mrd'
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.procType = '' // 아래 별지 출력 시 출력횟수 증가하지 않음
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20017.mrd' // 치매신탁별지 (운용지시서)
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (!TSCommUtil.gfn_isNull(this.ds_tfcprb.moGdC)) {
          // moGdC : 모상품코드
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchParam.moGdCYn = 'Y' //유언대용신탁 계약서
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20090.mrd'
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (!TSCommUtil.gfn_isNull(this.ds_detail04.invtRs)) {
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20050.mrd' // 적합성 보고서
          this.ds_searchReport.reportUrl = 'reportTFAC20000D'
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)

          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (this.ds_acno.trstTypC != '002' && this.ds_acno.trstTypC != '003') {
          // 유가증권신탁, 부동산신탁이 아닌경우
          //this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20014.mrd" // 일괄체크리스트 및 확인서
          this.ds_searchReport.reportMrdNm = 'TS000106' // 일괄체크리스트 및 확인서 ASR240700667 / [사랑on신탁] 서식_일괄체크리스트변경 2024.07.18
          this.ds_searchReport.reportUrl = 'reportTFAC20000A'
          //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
          this.ds_searchParam = {}
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          // 투자상품판매체크리스트
          this.ds_searchReport.reportMrdNm = 'TS000060'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = ''
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }
      }

      // this.fn_userInfoInit () // 사용자정보초기화
      // this.fn_searchAmtInit() // 조회금액항목초기화
      // this.fn_iamtInit     () // 입금금액항목초기화
      // this.fn_feeAmtInit   () // 수수료금액항목초기화
      console.log('리포트 리스트 ds_searchReportList : ', this.ds_searchReportList)

      this.fn_initReport()
      // this.fn_searchReport()
      // this.$refs["edt_acno"].focus()
    },

    fn_confirmPrintFalse() {
      this.fn_userInfoInit() // 사용자정보초기화
      this.fn_searchAmtInit() // 조회금액항목초기화
      this.fn_iamtInit() // 입금금액항목초기화
      this.fn_feeAmtInit() // 수수료금액항목초기화

      TSCommUtil.gfn_focus(this.$refs['edt_acno'])
      return false
    },

    /***************************************************************************************
          리포트 데이터 조회 
      *****************************************************************************************/
    fn_comma(val) {
      if (TSCommUtil.gfn_isNull(val)) return ''
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    fn_comEvent() {
      let acno = this.ds_searchAc.inAcno.replace(/[^0-9]/g, '')
      if (acno.length == 10) {
        this.fn_searchAcno()
      } else {
        this.fn_userInfoInit() // 사용자정보초기화
        this.fn_searchAmtInit() // 조회금액항목초기화
        this.fn_iamtInit() // 입금금액항목초기화
        this.fn_feeAmtInit() // 수수료금액항목초기화
        this.ds_searchAc.inAcno = acno
      }
    },

    /***************************************************************************************
          리포트 데이터 조회 
      *****************************************************************************************/
    async fn_searchReport_temp() {
      let lv_Vm = this
      let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
      let basInfo = lv_Vm.getStore('tsStore').getters.getBasInfo.data
      let isLast = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)

      // alert("isProcess : " + isProcess)
      // alert("isLast    : " + isLast)
      // alert("basInfo.procTyp :" + basInfo.procTyp)

      this.ds_searchReportList = []
      this.ds_searchParam = {}
      this.ds_searchParam.tacno = this.ds_acno.tacno
      this.ds_searchParam.bnkbSeq = this.ds_acno.bnkbSeq
      this.ds_searchParam.accSeq = this.ds_acno.accSeq
      this.ds_searchParam.ctno = Number(this.ds_acno.accSeq)
      // this.ds_searchParam.procType     = "PP"
      // this.ds_searchParam.signYn       = "N"
      this.ds_searchParam.csId = this.ds_acno.csId

      // // 전자서명 동의서
      // this.ds_searchReport.reportMrdNm = "TS000001"
      // this.ds_searchReport.reportUrl   = "selectTFCP10000"
      // this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      // this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 전자서명 동의서
      this.ds_searchReport.reportMrdNm = 'TS000001'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 계좌정보 조회
      let acnoInfo = await TSInfoUtil.gfn_getAcnoInfo(this, this.ds_searchParam)
      console.log('acnoInfo : ', acnoInfo)

      let tfac20000Rst = acnoInfo.tfac20000[0]
      let tfac20000Cs = acnoInfo.tfac20000[1]

      //필수 : TS000037_8.신탁 상설핵설  교부이해확인서
      this.ds_searchReport.reportMrdNm = 'TS000037'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      //상품설명서 상품코드(상품코드:gdC)로 맵핑(그 외 상품은 설명서 없음)
      if (tfac20000Rst.gdC == 'A25188') {
        // A25188 :TS000008_리치플랜하나금융지주56-2
        this.ds_searchReport.reportMrdNm = 'TS000008'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25189') {
        // A25189:TS000009_리치플랜 신한은행채권
        this.ds_searchReport.reportMrdNm = 'TS000009'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25190') {
        // A25190:TS000010_리치플랜_국고채(19-08)
        this.ds_searchReport.reportMrdNm = 'TS000010'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A41036') {
        // A41036:TS000011_리치플랜 예금
        this.ds_searchReport.reportMrdNm = 'TS000011'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A11004') {
        // A11004:TS000012_리치플랜MMT(G)
        this.ds_searchReport.reportMrdNm = 'TS000012'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25152') {
        // A25152:TS000013_마이크로소프트  회사채(2047년)
        this.ds_searchReport.reportMrdNm = 'TS000013'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25173') {
        // A25173:TS000014_마이크로소프트  회사체(2050년 만기)
        this.ds_searchReport.reportMrdNm = 'TS000014'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25185') {
        // A25185:TS000015_버라이즌  회사채(2031년 만기)
        this.ds_searchReport.reportMrdNm = 'TS000015'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25142') {
        // A25142:TS000016_버라이즌  회사채(2049년 만기)
        this.ds_searchReport.reportMrdNm = 'TS000016'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25182') {
        // A25182:TS000017_아마존  회사채(2030년 만기)
        this.ds_searchReport.reportMrdNm = 'TS000017'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25154') {
        // A25154:TS000018_아마존  회사채(2047년 만기)
        this.ds_searchReport.reportMrdNm = 'TS000018'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25180') {
        // A25180:TS000019_애플 회사채(2031년  만기)
        this.ds_searchReport.reportMrdNm = 'TS000019'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25146') {
        // A25146:TS000020_애플 회사채(2049년  만기)
        this.ds_searchReport.reportMrdNm = 'TS000020'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25181') {
        // A25181:TS000021_인텔 회사채(2030년  만기)
        this.ds_searchReport.reportMrdNm = 'TS000021'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25153') {
        // A25153:TS000022_인텔 회사채(2049년  만기)
        this.ds_searchReport.reportMrdNm = 'TS000022'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25148') {
        // A25148:TS000023_미국채   10년(2029년 만기)
        this.ds_searchReport.reportMrdNm = 'TS000023'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25191') {
        // A25191:TS000061_리치플랜국고채(19-06)
        this.ds_searchReport.reportMrdNm = 'TS000061'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25192') {
        // A25192:TS000062_리치플랜국고채(21-09)
        this.ds_searchReport.reportMrdNm = 'TS000062'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25193') {
        // A25193:TS000063_리치플랜국고채(22-14)
        this.ds_searchReport.reportMrdNm = 'TS000063'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25197') {
        // A25197:TS000064_리치플랜국고채(16-06)
        this.ds_searchReport.reportMrdNm = 'TS000064'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25198') {
        // A25198:TS000065_리치플랜국고채(20-07)
        this.ds_searchReport.reportMrdNm = 'TS000065'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25199') {
        // A25199:TS000066_$미국국채(2024)
        this.ds_searchReport.reportMrdNm = 'TS000066'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25200') {
        // A25200:TS000067_$미국국채(2043)
        this.ds_searchReport.reportMrdNm = 'TS000067'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25201') {
        // A25201:TS000068_$미국국채(2053)
        this.ds_searchReport.reportMrdNm = 'TS000068'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45100') {
        // A45100:TS000070_KODEX 2차전지산업 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000070'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45103') {
        // A45103:TS000071_KODEX 코스닥150 ETF
        this.ds_searchReport.reportMrdNm = 'TS000071'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45102') {
        // A45102:TS000072_KODEX200 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000072'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45101') {
        // A45101:TS000073_TIGER미국테크TOP10 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000073'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25211') {
        // A25211:TS000074_리치플랜대신ELB91회
        this.ds_searchReport.reportMrdNm = 'TS000074'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45104') {
        // A45104:TS000075_T-미국P반도체 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000075'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45105') {
        // A45105:TS000076_K-종합B 액티브 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000076'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45106') {
        // A45106:TS000077_K-단기채권 PLUS ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000077'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45107') {
        // A45107:TS000078_T-미국나스닥100 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000078'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45108') {
        // A45108:TS000079_T-미국S&P500 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000079'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45109') {
        // A45109:TS000080_A-고배당주 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000080'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45110') {
        // A45110:TS000081_T-200 IT ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000081'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45111') {
        // A45111:TS000082_S-미국배당 DJ. ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000082'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A25212') {
        // A25212:TS000083_리치플랜대신ELB92회
        this.ds_searchReport.reportMrdNm = 'TS000083'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45112') {
        // A45112:TS000088_T-MSCI Korea TR ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000088'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45113') {
        // A45113:TS000089_T-TOP10 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000089'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45114') {
        // A45114:TS000090_K-은행 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000090'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45115') {
        // A45115:TS000091_K-자동차 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000091'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45116') {
        // A45116:TS000092_K-반도체 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000092'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45117') {
        // A45117:TS000093_K-국고채 30년 액티브 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000093'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45118') {
        // A45118:TS000094_T-200 중공업 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000094'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45119') {
        // A45119:TS000095_T-차이나항셍테크 ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000095'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45120') {
        // A45120:TS000086_K-26-12회사채A.ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000086'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45121') {
        // A45121:TS000084_T-24-10회사채(A+이상)A.ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000084'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45122') {
        // A45122:TS000085_T-25-10회사채(A+이상)A.ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000085'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      if (tfac20000Rst.gdC == 'A45123') {
        // A45123:TS000087_H-24-09 회사채(AA-이상) A. ETF신탁
        this.ds_searchReport.reportMrdNm = 'TS000087'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }
      
        if (tfac20000Rst.gdC == 'A25213') {
          // A25213:TS000096_리치플랜대신ELB93회
          this.ds_searchReport.reportMrdNm = 'TS000096'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25217') {
          // A25218:TS000098_리치플랜 네이버4-2
          this.ds_searchReport.reportMrdNm = 'TS000098'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25218') {
          // A25218:TS000099_리치플랜서울도철23-06
          this.ds_searchReport.reportMrdNm = 'TS000099'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // ASR240300119 신규신탁 상품설명서 전자서식 매핑 2024.03.06

        if (tfac20000Rst.gdC == 'A25219') {
          // A25219:TS000100_$미국국채 0.375_2709
          this.ds_searchReport.reportMrdNm = 'TS000100'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25220') {
          // A25220:TS000101_$미국국채 0.5_2710
          this.ds_searchReport.reportMrdNm = 'TS000101'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25221') {
          // A25221:TS000102_$미국국채 0.625_3008
          this.ds_searchReport.reportMrdNm = 'TS000102'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25222') {
          // A25222:TS000103_리치플랜 국고채(23-10)
          this.ds_searchReport.reportMrdNm = 'TS000103'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25223') {
          // A25223:TS000104_리치플랜 국고채(23-6)
          this.ds_searchReport.reportMrdNm = 'TS000104'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25224') {
          // A25224:TS000105_리치플랜 국고채(21-2)
          this.ds_searchReport.reportMrdNm = 'TS000105'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // ASR240300879 신규신탁 상품설명서 전자서식 매핑 2024.03.25

        if (tfac20000Rst.gdC == 'A45124') {
          // A45124:TS000108_S-초단기채권 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000108'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45125') {
          // A45125:TS000109_S-반도체소부장 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000109'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45126') {
          // A45126:TS000110_A-미국채30년(H)ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000110'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45127') {
          // A45127:TS000111_K-선진국MSCI ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000111'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45128') {
          // A45128:TS000112_K-K로봇액티브 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000112'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45129') {
          // A45129:TS000113_T-현대차그룹 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000113'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // 2024.08.06 ASR240701107_상품설명서 갱신에 따른 태블릿 전자서식 수정
      

      // 고령자여부
      if (this.fn_lifeAge(this.ds_acno.rcno) >= this.oldAge) {
        // 고령자

        //상품등급 : ivRskRatingC : 1~6
        if (tfac20000Rst.ivRskRatingC == '1') {
          // 고령자/상품등급1 : TS000031_(개정_고령자1등급)신탁  핵심설명서_2208

          this.ds_searchReport.reportMrdNm = 'TS000038'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '2') {
          // 고령자/상품등급2 : TS000032_(개정_고령자2등급)신탁  핵심설명서_2208

          this.ds_searchReport.reportMrdNm = 'TS000032'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '3') {
          // 고령자/상품등급3 : TS000033_(개정_고령자3등급)신탁  핵심설명서_2208

          this.ds_searchReport.reportMrdNm = 'TS000033'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '4') {
          // 고령자/상품등급4 : TS000034_(개정_고령자4등급)신탁  핵심설명서_2208

          this.ds_searchReport.reportMrdNm = 'TS000034'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '5') {
          // 고령자/상품등급5 : TS000035_(개정_고령자5등급)신탁  핵심설명서_2208

          this.ds_searchReport.reportMrdNm = 'TS000035'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '6') {
          // 고령자/상품등급6 : TS000036_(개정_고령자6등급)신탁  핵심설명서_2208

          this.ds_searchReport.reportMrdNm = 'TS000036'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }
      } else {
        // 일반

        //상품등급 : ivRskRatingC : 1~6
        if (tfac20000Rst.ivRskRatingC == '1') {
          // 일반/상품등급1 : TS000025_1등급 신탁 핵심설명서

          this.ds_searchReport.reportMrdNm = 'TS000025'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '2') {
          // 일반/상품등급2 : TS000026_2등급 신탁 핵심설명서

          this.ds_searchReport.reportMrdNm = 'TS000026'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '3') {
          // 일반/상품등급3 : TS000027_3등급 신탁 핵심설명서

          this.ds_searchReport.reportMrdNm = 'TS000027'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '4') {
          // 일반/상품등급4 : TS000028_4등급 신탁 핵심설명서

          this.ds_searchReport.reportMrdNm = 'TS000028'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '5') {
          // 일반/상품등급5 : TS000029_5등급 신탁 핵심설명서

          this.ds_searchReport.reportMrdNm = 'TS000029'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (tfac20000Rst.ivRskRatingC == '6') {
          // 일반/상품등급6 : TS000030_6등급 신탁 핵심설명서

          this.ds_searchReport.reportMrdNm = 'TS000030'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }
      }

      // 해외상품여부 : frBondCpTc
      if (!TSCommUtil.gfn_isNull(tfac20000Rst.frBondCpTc)) {
        // 해외상품여부 Y

        // TS000038_외화채권 특정금전신탁  위험고지 확인서
        this.ds_searchReport.reportMrdNm = 'TS000038'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

        // TS000024_필수_개인(신용)정보  처리동의서_(해외채권 신탁용)
        this.ds_searchReport.reportMrdNm = 'TS000024'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      // (삼성생명신탁)특정금전신탁 계약서 개정(시행_20210818)
      this.ds_searchReport.reportMrdNm = 'TS000047'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 2301(삼성생명)Whole Life신탁 특약 개정(시행_230126)
      this.ds_searchReport.reportMrdNm = 'TS000048'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 2301(삼성생명)유가증권신탁 계약서 개정(시행_230126)
      this.ds_searchReport.reportMrdNm = 'TS000049'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 2301(삼성생명)을종부동산관리신탁 계약서 개정(시행_230126)
      this.ds_searchReport.reportMrdNm = 'TS000050'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 일괄 체크리스트 및 확인서
      this.ds_searchReport.reportMrdNm = 'TS000106' // 일괄체크리스트 및 확인서 ASR240700667 / [사랑on신탁] 서식_일괄체크리스트변경 2024.07.18
      this.ds_searchReport.reportUrl = 'reportTFAC20000A'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 특정금전신탁 운용지시서
      this.ds_searchReport.reportMrdNm = 'TS000052'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 신탁계약 변경신청서
      this.ds_searchReport.reportMrdNm = 'TS000055'
      this.ds_searchReport.reportUrl = 'reportTFAC20000C'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // 투자상품판매체크리스트
      this.ds_searchReport.reportMrdNm = 'TS000060'
      this.ds_searchReport.reportUrl = ''
      this.ds_searchReport.reportParam = ''
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      this.fn_initReport()
      this.fn_checkProcess()
    },

    /***************************************************************************************
          AR 조회 후 콜백 
      *****************************************************************************************/
    fn_callBack_TEST(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }
    },

    /***************************************************************************************
          리포트 데이터 조회 
      *****************************************************************************************/
    fn_initReport() {
      this.ds_resultReportList = []
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        // alert(this.ds_searchReportList[i].reportMrdNm)
        let report = { formId: this.ds_searchReportList[i].reportMrdNm }
        report.params = this.ds_searchReportList[i]
        report.mappingData = this.ds_searchReportList[i].mappingData
        this.ds_resultReportList.push(JSON.parse(JSON.stringify(report)))
      }
    },

    /***************************************************************************************
          리포트 데이터 조회 
      *****************************************************************************************/
    async fn_searchReport() {
      let isProcess = this.getStore('tsStore').getters.getState.isProcess
      let isLast = this.$bizUtil.tsUtils.getIsLastProc(this)

      if (isProcess) {
        if (!isLast) {
          if (this.lv_report == 'ADD') {
            TSInfoUtil.commReportInfo(this, this.ds_resultReportList)
            this.fn_confirmCommissionTrue()
            return
          }
          TSInfoUtil.commReportInfo(this, this.ds_resultReportList, this.fn_movePage_MSPTS213M, false)
        } else {
          TSInfoUtil.commReportInfo(this, this.ds_resultReportList)
        }
      } else {
        // 프로세스 진행 중이 아닐 경우
        TSInfoUtil.commReportInfo(this, this.ds_resultReportList)
        if (this.lv_report == 'ADD') {
          this.fn_confirmCommissionTrue()
        }
      }

      // this.fn_userInfoInit () // 사용자정보초기화
      // this.fn_searchAmtInit() // 조회금액항목초기화
      // this.fn_iamtInit     () // 입금금액항목초기화
      // this.fn_feeAmtInit   () // 수수료금액항목초기화
    },

    /******************************************************************************
     * Function명 : fn_movePage_MSPTS213M
     * 설명       : 송금계좌등록화면으로 이동
     ******************************************************************************/
    fn_movePage_MSPTS213M() {
      let ds_move = {}
      ds_move.tacno = this.ds_acno.tacno
      ds_move.bnkbSeq = this.ds_acno.bnkbSeq
      ds_move.accSeq = this.ds_acno.accSeq

      this.$router.push({ name: 'MSPTS213M', params: ds_move })
    },

    /******************************************************************************
     * Function명 : fn_RecordConfirm
     * 설명       : 녹취 확인
     ******************************************************************************/
    fn_RecordConfirm() {
      console.log('fn_RecordConfirm.....')
      this.$refs.tsHeader.fn_Record()
    },

    /**********************************************************************************
     * Function명 : fn_lifeAge
     * 설명       : 고령자 체크를 위한 로직
     **********************************************************************************/
    fn_lifeAge(rcno) {
      //파생상품 위험도 팝업
      let vBirth = '' //생년월일
      let aaYear = '19'
      let busyDate = this.ds_userInfo.busyDate

      rcno = rcno.replaceAll('-', '')
      let aDate = rcno.substr(0, 6) // 주민번호 앞자리만
      let sex = rcno.substr(6, 1) //성별

      //주민번호가 3이상이면 2000년도 출생
      if (sex == '3' || sex == '4' || sex == '7' || sex == '8') {
        aaYear = '20'
      }

      vBirth = aaYear + aDate
      //나이계산
      let aYear = this.fn_getNumber(vBirth.substr(0, 4))
      let aMonth = this.fn_getNumber(vBirth.substr(4, 2))
      let aDay = this.fn_getNumber(vBirth.substr(6, 2))

      let bYear = this.fn_getNumber(busyDate.substr(0, 4))
      let bMonth = this.fn_getNumber(busyDate.substr(4, 2))
      let bDay = this.fn_getNumber(busyDate.substr(6, 2))

      if (aMonth - bMonth > 0) {
        //생일 월 안지난경우
        aYear = aYear + 1
      } else if (aMonth - bMonth == 0) {
        if (aDay - bDay > 0) {
          //생일 일자 안지난경우
          aYear = aYear + 1
        }
      }
      //this.lifeAge.set_value( bYear - aYear );
      return bYear - aYear
    },

    fn_getNumber(val, defaultVal) {
      //mode:return value (not null).return defaultVal, (0 or null).return 0
      if (defaultVal == undefined || defaultVal == null) defaultVal = 0
      if (val == undefined || val == null || val == '') return defaultVal
      return Number(val)
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {}
}
</script>

<style lang="scss"></style>

<style scoped></style>
